import React from 'react'
import { getData } from 'services/apiService'

const initialState = { data: {}, datas: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_EACH_DATA':
      return { ...state, data: action.payload.data }
    case 'SET_DATA':
      return { ...state, datas: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useSelfTesting = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchTesting = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(`self_testing?id=` + id)
    dispatch({ type: 'SET_EACH_DATA', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchSelectTestingReport = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(
      `self_testing_report?${new URLSearchParams(query)}`
    )

    dispatch({ type: 'SET_EACH_DATA', payload: { data: response } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchTestings = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(`self_testings?&${new URLSearchParams(query)}`)
    dispatch({ type: 'SET_DATA', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return { fetchTesting, fetchTestings, state: state, fetchSelectTestingReport }
}

export default useSelfTesting
