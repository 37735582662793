import TextBox from 'components/Inputs/TextBox'
import React, { useState } from 'react'
import Select from 'react-select'
import { Row, Col, Button } from 'reactstrap'
import { capitalizeFirstLetter } from 'utli'
function FilterBox({ headers, onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  }
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState()
  headers = headers.filter((h) => h !== 'No')
  headers.push('client_code')
  const searchOptions = headers.map((h) => {
    return {
      label: capitalizeFirstLetter(h.replace('_', ' ')),
      value: h,
    }
  })

  return (
    <>
      <Row>
        <Col md={5} xs={5}>
          <Select
            styles={customStyles}
            options={searchOptions}
            onChange={(e) => {
              setSearchType(e.value)
              setSearch('')
            }}
          />
        </Col>
        <Col md={5} xs={5}>
          <TextBox
            styles={{ height: 34 }}
            placeholder={searchType}
            onChange={(e) => setSearch(e.target.value)}
            value={typeof search === 'object' ? '' : search}
          />
        </Col>

        {searchType && (
          <Button
            size="sm"
            color="success"
            style={{ height: 35 }}
            onClick={() => {
              if (search) {
                onSearch({ filter: searchType, value: search })
                setSearch('')
              }
            }}
          >
            <i className="fa fa-search" />
          </Button>
        )}
      </Row>
    </>
  )
}

export default FilterBox
