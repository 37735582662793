import React from 'react'
import {
  Modal,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
} from 'reactstrap'
import LinkageToCareDetailContent from './DetailDialog'

function LinkageToCareDetailDialog({ data, modalOpen, onToggle }) {
  return (
    <Modal
      isOpen={modalOpen}
      size="lg"
      backdrop={true}
      style={{ top: '1%', maxWidth: '50%' }}
    >
      <div className="pt-3 pb-3 pr-2" style={{ background: '#e9e9ef' }}>
        <button
          aria-label="Close"
          className=" close"
          type="button"
          onClick={() => onToggle(!modalOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className="waybill-detail-dialog">
        <Row className="d-flex justify-content-center">
          <Card style={{ width: '80%' }}>
            <CardHeader className="bg-primary text-center">
              <h2 className="text-white">Detail</h2>
            </CardHeader>
            <CardBody>
              <LinkageToCareDetailContent data={data} />
            </CardBody>
          </Card>
        </Row>
      </ModalBody>
    </Modal>
  )
}
export default LinkageToCareDetailDialog
