import React, { useCallback } from 'react'
import { getData } from 'services/apiService'

const initialState = {
  data: {
    ngos: [],
    clinics: [],
    facilities: [],
  },
  loading: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_OPTIONS':
      return { ...state, data: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error('Unhandled action type')
  }
}

const useSelfTestingOptions = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchOptions = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true })

    try {
      // Fetch data from the backend
      const [ngosResponse, clinicsResponse, facilitiesResponse] =
        await Promise.all([
          getData(`ngos`),
          getData(`clinics`),
          getData(`facilities`),
        ])

      // Extract data from responses
      const data = {
        ngos: ngosResponse.data,
        clinics: clinicsResponse.data,
        facilities: facilitiesResponse.data,
      }

      dispatch({ type: 'SET_OPTIONS', payload: { data } })
    } catch (error) {
      // Handle error (optional)
      console.error('Error fetching options:', error)
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }, [])

  return { fetchOptions, state }
}

export default useSelfTestingOptions
