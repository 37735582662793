import React from 'react'
import { Row, Card, CardBody, CardHeader } from 'reactstrap'
import usePatient from 'hooks/usePatient'
import { useParams } from 'react-router'

import DetailDialog from 'components/detailDialog/DetailDialog'
import useLinkageToCare from 'hooks/useLinkageToCare'
function PatientDetail() {
  const { fetchPatient, state: patientState } = usePatient()
  const { getLastDate, state } = useLinkageToCare()
  const [loading, setLoading] = React.useState(false)
  const { id } = useParams()

  React.useEffect(() => {
    async function loadData() {
      setLoading(true)
      await fetchPatient(id)
      await getLastDate(id)
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <div>
      <Row className="d-flex justify-content-center">
        {patientState.data && !loading && (
          <Card style={{ width: '60%', marginTop: 20 }}>
            <CardHeader className="bg-primary text-center d-flex justify-content-between">
              <h2 className="text-white" style={{ flex: 1 }}>
                Detail
              </h2>
              <span
                style={{ color: 'white', fontSize: 22 }}
                onClick={() => {
                  window.history.go(-1)
                }}
              >
                X
              </span>
            </CardHeader>
            <CardBody>
              <DetailDialog
                data={patientState.data}
                lastDate={state.lastDate}
              />
            </CardBody>
          </Card>
        )}
      </Row>
    </div>
  )
}

export default PatientDetail
