import React, { useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import AdminNavbar from 'components/Navbars/AdminNavbar'
import ReportTable from './ReportTable'
import '../../../styles/reportTableStyle.css'
import { CardBody, Card, CardTitle } from 'reactstrap'
import FilterBox from './filter'
import useReport from 'hooks/useReport'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import FullScreenLoading from 'components/FullScreenLoading'
import dayjs from 'dayjs'

function Report() {
  const [dates, setDates] = useState(null)
  const tableRef = React.useRef(null)
  const { state, fetchReport } = useReport()
  const router = useHistory()
  const { start_date, end_date, filter } = queryString.parse(
    router.location.search
  )

  React.useEffect(() => {
    if (start_date && end_date) {
      fetchReport(queryString.parse(router.location.search))
    }
  }, [router.location.search])

  if (state.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <AdminNavbar
        toggleSidenav={true}
        sidenavOpen={() => {}}
        brandText={''}
        content={
          <div
            style={{ width: '100%' }}
            className="pb-2 d-flex align-items-center justify-content-between"
          >
            <span
              style={{ fontSize: 30, color: 'black', cursor: 'pointer' }}
              onClick={() => {
                window.history.go(-1)
              }}
            >
              🔙
            </span>
            <h3 style={{ flex: 1, textAlign: 'center' }}>Report</h3>
            <div className="mt-2 mb-2 button-container">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-secondary"
                table="table-to-xls"
                filename={`report-${dayjs(new Date()).format(
                  'DD/MM/YY hh:ss'
                )}`}
                sheet="tablexls123"
                buttonText="Export"
              />
            </div>
          </div>
        }
      />
      <div className=" d-flex justify-content-center mt-3">
        <div className="col-12">
          <Card>
            <CardTitle>
              <FilterBox
                onDateChange={(dates) => setDates(dates)}
                onClick={() =>
                  dates !== null
                    ? router.push(
                        `/report?start_date=${dates[0]}&end_date=${dates[1]}&filter=${filter}`
                      )
                    : router.push('/report')
                }
              />
            </CardTitle>
            {/* {start_date && end_date && ( */}
              <CardBody style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <ReportTable data={state.data} ref={tableRef} />
              </CardBody>
            {/* )} */}
          </Card>
        </div>
      </div>
    </>
  )
}
export default Report
