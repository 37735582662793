import React, { useEffect, useRef } from 'react'
import QRCode from 'react-qr-code'
import './index.scss'
import useSelfTesting from 'hooks/useSelfTesting'
import Button from 'components/Button'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { usePrint } from 'hooks/usePrint'
import { downloadPNG } from 'utli/downloadImg'
const jsonEncodedQrName = 'self_testing_json_encoded'
const qrName = 'self_testing'
function SaleTestingResult() {
  const componentRef = useRef()
  const jsonEncodedComponentRef = useRef()

  const { id } = useParams()

  const { state: testingData, fetchTesting } = useSelfTesting()

  useEffect(() => {
    async function loadData() {
      fetchTesting(id)
    }
    loadData()
  }, [id])

  const handleComponentPrint = (element) => {
    downloadPNG(element, `self_testing_QR.png`)
  }
  // const handleJsonEncodedComponentPrint = usePrint(jsonEncodedComponentRef)

  const handleJsonEncodedComponentPrint = (element) => {
    downloadPNG(element, `self_testing_QR.png`)
  }
  if (!testingData.data) {
    return (
      <div
        className="self-testing-result-container"
        style={{ display: 'flex' }}
      >
        Not Found
      </div>
    )
  }
  return (
    <div className="self-testing-result-container" style={{ display: 'flex' }}>
      <h3>အဖြေပို့ပြီးပါပြီ</h3>
      <div className="d-flex flex-column flex-lg-row" style={{ gap: '50px' }}>
        <div>
          <div ref={componentRef} style={{ textAlign: 'center' }}>
            <div className={qrName}>
              <QRCode
                value={`https://datadb.16859307-26-20220110223542.webstarterz.com/self_testing_view/${testingData.data.code}`}
                size="250"
              />
            </div>
            <h4>Code: {testingData.data.code}</h4>
          </div>
          <Button onClick={() => handleComponentPrint(qrName)}>သိမ်းမည်</Button>
        </div>

        <div>
          <div ref={jsonEncodedComponentRef} style={{ textAlign: 'center' }}>
            <div className={jsonEncodedQrName}>
              <QRCode value={JSON.stringify(testingData.data)} size="250" />
            </div>
            <h4>Code: {testingData.data.code}</h4>
          </div>
          <Button
            onClick={() => handleJsonEncodedComponentPrint(jsonEncodedQrName)}
          >
            သိမ်းမည်
          </Button>
        </div>
      </div>
      <h3>
        ယခု QR Code အား မိမိဖုန်းထဲတွင် သိမ်းဆည်းထားပါ <br />
        ကျန်းမာရေး ဝန်ဆောငိမှုများ ရယူသောအခါ ပြပေးပါ
      </h3>
    </div>
  )
}

export default SaleTestingResult
