import DatePicker from 'components/Inputs/DatePicker'
import TextBox from 'components/Inputs/TextBox'
import React, { useState } from 'react'
import Select from 'react-select'
import { Row, Col, Button } from 'reactstrap'

function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  }
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState()

  const [startDate,setStartDate]=useState("");
  const [endDate,setEndDate]=useState("");

  const searchOptions = [
    { label: 'Code', value: 'code' },
    { label: 'Distribution Point', value: 'fromWhere' },
    { label: 'Date', value: 'date' },
  ]

  return (
    <>
      <Row>
        <Col md={5} xs={5}>
          <Select
            styles={customStyles}
            options={searchOptions}
            onChange={(e) => {
              setSearchType(e.value)
              setSearch('')
            }}
          />
        </Col>
        {searchType !== 'date' && (
          <Col md={5} xs={5}>
            <TextBox
              styles={{ height: 34 }}
              placeholder={searchType}
              onChange={(e) => setSearch(e.target.value)}
              value={typeof search === 'object' ? '' : search}
            />
          </Col>
        )}

        {searchType === 'date' && (
          <Col md={5} xs={5}>
            <div className='d-flex justify-content-between align-items-center'>
              <DatePicker placeholder="Start Date" onChange={(data)=>setStartDate(data)} value={startDate}/>
              <DatePicker placeholder="End Date" onChange={(data)=>setEndDate(data)} endDate={endDate} />
            </div>
          </Col>
        )}

        {searchType && (
          <Button
            size="sm"
            color="success"
            style={{ height: 35 }}
            onClick={() => {
              if (searchType!=="date" && search) {
                onSearch({ filter: searchType, value: search })
                setSearch('')
              }

          

              if (searchType==="date" && startDate && endDate) {
                onSearch({ filter: searchType, startDate:startDate,endDate:endDate })
                setSearch('')
              }
            }}
          >
            Search
          </Button>
        )}
      </Row>
    </>
  )
}

export default FilterBox
