import React from 'react'
import { getData } from 'services/apiService'

const initialState = { datas: [], loading: false, lastDate: {} }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LINKAGE':
      return { ...state, datas: action.payload.data }
    case 'SET_LAST_DATE':
      return { ...state, lastDate: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }

    default:
      throw new Error()
  }
}

const useLinkageToCare = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const getLastDate = async (id) => {
    let response = await getData(`lastdate?id=${id}`)
    dispatch({ type: 'SET_LAST_DATE', payload: { data: response } })
  }

  const fetchLinkages = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(
      `treatments-data?&${new URLSearchParams(query)}`
    )

    dispatch({ type: 'SET_LINKAGE', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return { fetchLinkages, getLastDate, state: state }
}

export default useLinkageToCare
