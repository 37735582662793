import React from 'react'
import Table from 'components/Table'

function List({ headers, data, title, buttons, filter }) {
  return (
    <Table
      title={title}
      headers={headers.map((e, index) => (
        <>
          <th scope="col" key={`thead-${Math.random()}`}>
            {e.replace('_', ' ')}
          </th>
        </>
      ))}
      filter={filter}
      buttons={buttons}
      rows={data?.map((e, index) => {
        return (
          <tr>
            <td>{index+1}</td>
            <td>{e.code}</td>
            <td>{e.gender}</td>
            <td>{e.fromWhere}</td>
            <td>{e.fromWhere==="other" || e.fromWhere==="online_delivery"?e.otherText:`${e.fromDescription} ${e.fromDescription==="Other"?`(${e.otherText})`:''}`}</td>
            <td>{e.is_assisted}</td>
            <td>{e.risk_group}</td>
            <td>{e.result}</td>
            <td>{e.created_at}</td>
          </tr>
        )
      })}
    />
  )
}

export default List
