import { useMemo } from 'react'

export default function ReactiveNonReactiveTable({ data }) {
  const sources = useMemo(() => {
    return [...new Set(data?.flatMap((item) => Object.keys(item.total)))]
  }, [data])
  return (
    <table
      className="table table-bordered table-sm d-none"
      id="table_reactiveNonReactive_results"
    >
      <thead>
        <tr className="text-center">
          <th className="align-middle" width={50}>
            Date
          </th>
          {sources?.map((source) => (
            <th className="align-middle" width={50}>
              {source}
            </th>
          ))}
          <th className="align-middle" width={50}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => {
          let total = 0
          return (
            <tr>
              <td className="align-middle">{row?.date}</td>
              {sources?.map((source) => {
                total += row?.total[source] ?? 0
                return <td className="align-middle">{row?.total[source]}</td>
              })}
              <td className="align-middle">{total}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
