import React from 'react'
import { getData } from 'services/apiService'

const initialState = { datas: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TESTING':
      return { ...state, datas: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTesting = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchTestings = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(`invs-data?&${new URLSearchParams(query)}`)
    dispatch({ type: 'SET_TESTING', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return { fetchTestings, state: state }
}

export default useTesting
