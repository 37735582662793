import DateRangePicker from 'components/Inputs/DateRangePicker'
import SelectBox from 'components/SelectBox'
import React, {useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Row, Col, Button } from 'reactstrap'
import { chooseClinicName } from 'variables/general'
import { formatSelfTestingOptions } from 'variables/general'
import { chooseFacilityName } from 'variables/general'
import { chooseNGOName } from 'variables/general'
import { FROM_WHERE_OPTIONS } from 'variables/general'

function FilterBox({ onDateChange, onClick, options }) {
  const { control, handleSubmit } = useForm()

  const fromWhere = useWatch({
    control,
    name: 'fromWhere',
  })

  const ngoOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.ngos)
  }, [options?.data?.ngos])

  const facilityOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.facilities)
  }, [options?.data?.facilities])
  const clinicOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.clinics)
  }, [options?.data?.clinics])

  return (
    <Row className="d-flex align-items-center pt-3 pl-3">
      <Col
        md={6}
        className="d-flex align-items-center justify-content-center"
        style={{
          gap: '10px',
        }}
      >
        <Col md={6} xs={12}>
          <h2 className="p-0 m-0">Distribution Point:</h2>
          <SelectBox
            control={control}
            className="form-control"
            name="fromWhere"
            rules={{ required: false }}
            label={'Distribution Point'}
            options={FROM_WHERE_OPTIONS}
          />
        </Col>
        {(fromWhere === 'ngo' ||
          fromWhere === 'pharmancy' ||
          fromWhere === 'facility') && (
          <Col md={6} xs={12}>
            {fromWhere === 'pharmancy' && <h2>{chooseClinicName}</h2>}
            {fromWhere === 'ngo' && <h2>{chooseNGOName}</h2>}
            {fromWhere === 'facility' && <h2>{chooseFacilityName}</h2>}
            <SelectBox
              control={control}
              className="form-control"
              name="fromDescription"
              rules={{ required: false }}
              label={
                fromWhere === 'ngo'
                  ? chooseNGOName
                  : fromWhere === 'facility'
                  ? chooseFacilityName
                  : chooseClinicName
              }
              options={
                fromWhere === 'ngo'
                  ? ngoOptions
                  : fromWhere === 'facility'
                  ? facilityOptions
                  : clinicOptions
              }
            />
          </Col>
        )}
      </Col>
      <Col md={5} xs={5}>
        <DateRangePicker onDateChange={onDateChange} />
      </Col>
      <Button
        size="sm"
        color="success"
        style={{ height: 35 }}
        onClick={handleSubmit(onClick)}
      >
        <i className="fa fa-search" />
      </Button>
    </Row>
  )
}

export default FilterBox
