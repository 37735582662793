import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { capitalizeFirstLetter } from 'utli'
import Select from 'react-select'
import TextBox from 'components/Inputs/TextBox'

function FilterBox({ onSearch, headers }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  }

  const [searchType, setSearchType] = React.useState('')
  const [search, setSearch] = React.useState()
  headers = headers.filter((e) => e !== 'No')
  headers.push('client_code')
  const searchOptions = headers.map((e) => {
    return {
      label: capitalizeFirstLetter(e.replace('_', ' ')),
      value: e,
    }
  })

  return (
    <>
      <Row>
        <Col md="5" xs="5">
          <Select
            styles={customStyles}
            onChange={(val) => {
              setSearch('')
              setSearchType(val.value)
            }}
            options={searchOptions}
          />
        </Col>
        <Col md="5" xs="5">
          <TextBox
            onChange={(e) => setSearch(e.target.value)}
            style={{ height: 34 }}
            value={typeof search === 'object' ? '' : search}
            placeholder={`${searchType}`}
          />
        </Col>

        {searchType && (
          <Button
            size="sm"
            color="success"
            style={{ height: 35 }}
            onClick={() => {
              if (search) {
                setSearch('')
                // const searchData = {}
                // if (search) searchData[searchType] = search

                onSearch({
                  filter: searchType,
                  value: search,
                })
              }
            }}
          >
            <i className="fa fa-search" />
          </Button>
        )}
      </Row>
    </>
  )
}

export default FilterBox
