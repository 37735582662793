import { Bar } from 'react-chartjs-2'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { barChartConfig } from './chartConfig'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default function BarChart({
  data,
  title,
  uniqueChartClassName,
  option = barChartConfig,
}) {
  return (
    <Card style={{ height: '400px' }}>
      <CardBody>
        <Row>
          <Col>
            <h3 className='text-center'>{title}</h3>
          </Col>
        </Row>
        <div className={`${uniqueChartClassName}`}>
          <Bar data={data} options={option} plugins={[ChartDataLabels]} />
        </div>
      </CardBody>
    </Card>
  )
}
