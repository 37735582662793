
export default function ReactiveTable({ data }) {
  return (
    <table
      className="table table-bordered table-sm d-none"
      id="table_reactive_results"
    >
      <thead>
        <tr className="text-center">
          <th className="align-middle" width={50}>
            Date
          </th>
          <th className="align-middle" width={50}>
            Reactive
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => {
          return (
            <tr>
              <td className="align-middle">{row?.date}</td>
              <td className="align-middle">{row?.total?.reactive}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
