import React, { useEffect } from 'react'
import './index.scss'
import useSelfTesting from 'hooks/useSelfTesting'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import './index.scss'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
function SaleTestingView() {
  const { code } = useParams()

  const { state: testingData, fetchTesting } = useSelfTesting()

  useEffect(() => {
    async function loadData() {
      fetchTesting(code)
    }
    loadData()
  }, [code])

  if (testingData.loading) {
    return (
      <div className="self-testing-view-container" style={{ display: 'flex' }}>
        Loading...
      </div>
    )
  }

  if (!testingData.data) {
    return (
      <div className="self-testing-view-container" style={{ display: 'flex' }}>
        Not Found
      </div>
    )
  }

  return (
    <div className="self-testing-view-container" style={{ display: 'flex' }}>
      <Card style={{ width: '50%' }}>
        <div style={{ padding: '20px 40px' }}>
          <h3>Patient Info</h3>
        </div>
        <CardBody>
          <div className="patient-info">
            <p>Code</p>
            <p>{testingData.data.code}</p>
          </div>
          <div className="patient-info">
            <p>Gender</p>
            <p>{testingData.data.gender}</p>
          </div>
          <div className="patient-info">
            <p>Rist Group </p>
            <p>{testingData.data?.risk_group}</p>
          </div>
          <div className="patient-info">
            <p>Received From </p>
            <p>{testingData.data.fromWhere}</p>
          </div>
          <div className="patient-info">
            <p>Received From Description</p>
            <p>
              {testingData.data.fromWhere === 'other' ||
              testingData.data.fromWhere === 'online_delivery'
                ? testingData.data.otherText
                : testingData.data.fromDescription === 'Other'
                ? `Other (${testingData.data.otherText})`
                : testingData.data.fromDescription}
            </p>
          </div>
          {(testingData.data.fromWhere === 'ngo' ||
            testingData.data.fromWhere === 'facility') && (
            <div className="patient-info">
              <p>Assisted </p>
              <p>{testingData.data?.is_assisted}</p>
            </div>
          )}
          <div className="patient-info">
            <p>Result</p>
            <p>{testingData.data.result}</p>
          </div>
          <div className="patient-info">
            <p>Submitted Date</p>
            <p>{testingData.data.created_at}</p>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default SaleTestingView
