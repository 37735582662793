import html2canvas from 'html2canvas'

export const downloadPNG = (className, filename) => {
  let input = window.document.getElementsByClassName(className)[0]

  html2canvas(input).then((canvas) => {
    // Convert the canvas to a data URL
    const img = canvas.toDataURL('image/png')

    // Create a temporary link element
    const link = document.createElement('a')
    link.href = img
    link.download = filename

    // Trigger the download by simulating a click event
    link.click()
  })
}
