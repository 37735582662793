import React, { useCallback, useEffect, useMemo } from 'react'
import './index.scss'
import { Col, Form, Row } from 'reactstrap'
import Clinic from 'assets/img/icons/clinic.png'
import Ngo from 'assets/img/icons/ngo.png'
import Positive from 'assets/img/icons/positive.png'
import Negative from 'assets/img/icons/negative.png'
import Other from 'assets/img/icons/other.png'
import OnlineShop from 'assets/img/icons/online_shop.png'
import { useForm, useWatch } from 'react-hook-form'
import SelectBox from 'components/SelectBox'
import TextBox from 'components/Inputs/TextBox'
import { sendData } from 'services/apiService'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Button from 'components/Button'
import { RISK_GROUP_OPTIONS } from 'variables/general'
import { ASSISTED_OPTIONS } from 'variables/general'
import { GENDER_OPTIONS } from 'variables/general'
import { chooseClinicName } from 'variables/general'
import { chooseNGOName } from 'variables/general'
import { chooseFacilityName } from 'variables/general'
import { chooseOther } from 'variables/general'
import { chooseOnlineDelivery } from 'variables/general'
import useSelfTestingOptions from 'hooks/useSelfTestingOptions'
import { formatSelfTestingOptions } from 'variables/general'

function SelfTestingCreate() {
  const history = useHistory()
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from: 'pharmancy',
      fromDescription: '',
      otherText: '',
      result: 'non_reactive',
    },
  })
  const { state: options, fetchOptions } = useSelfTestingOptions()
  useEffect(() => {
    async function loadData() {
      await fetchOptions()
    }
    loadData()
  }, [fetchOptions])

  const ngoOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.ngos)
  }, [options?.data?.ngos])

  const facilityOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.facilities)
  }, [options?.data?.facilities])
  const clinicOptions = useMemo(() => {
    return formatSelfTestingOptions(options?.data?.clinics)
  }, [options?.data?.clinics])

  const onSubmit = useCallback(async (data) => {
    try {
      const response = await sendData('self_testing/save', data)
      history.replace('/self_testing/result/' + response.data.id)
    } catch (e) {
      NotificationManager.error('Something wrong')
    }
  }, [])

  const watchForm = useWatch({ name: 'from', control })
  const watchResult = useWatch({ name: 'result', control })
  const watchFormDescription = useWatch({ name: 'fromDescription', control })

  if (options?.loading) {
    return (
      <div className="self-testing-view-container" style={{ display: 'flex' }}>
        Loading...
      </div>
    )
  }
  return (
    <div className="self-testing-create-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-center">
          <h2>အဖြေပို့ရန်</h2>
        </div>
        <div className="form-wrapper">
          <div className="form-item">
            <h2>Age</h2>
            <div className="py-2 px-3">
              <TextBox
                className="form-control"
                placeholder="Age"
                type="number"
                registerProps={register('age', {
                  required: 'Age is required',
                })}
                errors={errors.age}
                min={0}
                max={99}
              />
            </div>
          </div>
          <div className="form-item">
            <h2>ကျား/မ</h2>
            <div className="py-2 px-3">
              <SelectBox
                control={control}
                className="form-control"
                name="gender"
                rules={{ required: true }}
                label={'ကျား/မ'}
                options={GENDER_OPTIONS}
              />
            </div>
          </div>
          <div className="form-item">
            <h2>မိမိကိုယ်ကို မည်သို့ ခံယူ ထားပါသလဲ</h2>
            <div className="py-2 px-3">
              <SelectBox
                control={control}
                className="form-control"
                name="risk_group"
                rules={{ required: true }}
                label={'မိမိကိုယ်ကို မည်သို့ ခံယူ ထားပါသလဲ'}
                options={RISK_GROUP_OPTIONS}
              />
            </div>
          </div>
          <div className="form-item">
            <p>
              မိမိကိုယ်တိုင် သွေးစစ်ဆေးမှုအတွက် စစ်ဆေးရန် test kit ကို
              မည်သည့်နေရမှာ ရယူခဲ့ပါသလဲ ?
            </p>
            <div className="selector-container">
              <Row className="d-flex justify-content-center">
                <Col md="4" sm="12">
                  <div
                    onClick={() => setValue('from', 'pharmancy')}
                    className={`icon-wrapper ${
                      watchForm === 'pharmancy' ? 'selected' : ''
                    }`}
                  >
                    <img
                      src={`https://png.pngtree.com/template/20190115/ourmid/pngtree-vector-medical-house-clinic-logo-design-template-image_51902.jpg`}
                      className="self-testing-icon"
                    />
                    <h4>ဆေးခန်း</h4>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <div
                    onClick={() => setValue('from', 'ngo')}
                    className={`icon-wrapper ${
                      watchForm === 'ngo' ? 'selected' : ''
                    }`}
                  >
                    <img src={Ngo} className="self-testing-icon" />
                    <h4>NGO/INGO Peers များမှ တစ်ဆင့်</h4>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <div
                    onClick={() => setValue('from', 'facility')}
                    className={`icon-wrapper ${
                      watchForm === 'facility' ? 'selected' : ''
                    }`}
                  >
                    <img src={Clinic} className="self-testing-icon" />
                    <h4>ဝန်ဆောင်မှုပေးသည့် ဌာန များမှ တစ်ဆင့်</h4>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <div
                    onClick={() => setValue('from', 'other')}
                    className={`icon-wrapper ${
                      watchForm === 'other' ? 'selected' : ''
                    }`}
                  >
                    <img src={Other} className="self-testing-icon" />
                    <h4>အခြား</h4>
                  </div>
                </Col>
                <Col md="4" sm="12">
                  <div
                    onClick={() => setValue('from', 'online_delivery')}
                    className={`icon-wrapper ${
                      watchForm === 'online_delivery' ? 'selected' : ''
                    }`}
                  >
                    <img src={OnlineShop} className="self-testing-icon" />
                    <h4>အွန်လိုင်းမှ မှာယူခြင်း</h4>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="form-item">
            {watchForm === 'pharmancy' && <h2>{chooseClinicName}</h2>}
            {watchForm === 'ngo' && <h2>{chooseNGOName}</h2>}
            {watchForm === 'facility' && <h2>{chooseFacilityName}</h2>}
            {watchForm === 'other' && <h2>{chooseOther}</h2>}
            {watchForm === 'online_delivery' && <h2>{chooseOnlineDelivery}</h2>}
            <div className="py-2 px-3">
              {(watchForm === 'ngo' ||
                watchForm === 'pharmancy' ||
                watchForm === 'facility') && (
                <SelectBox
                  control={control}
                  className="form-control"
                  name="fromDescription"
                  rules={{ required: true }}
                  label={
                    watchForm === 'ngo'
                      ? chooseNGOName
                      : watchForm === 'facility'
                      ? chooseFacilityName
                      : chooseClinicName
                  }
                  options={
                    watchForm === 'ngo'
                      ? ngoOptions
                      : watchForm === 'facility'
                      ? facilityOptions
                      : clinicOptions
                  }
                />
              )}
              {(watchForm === 'other' || watchFormDescription === 'Other') && (
                <TextBox
                  className="form-control"
                  placeholder={chooseOther}
                  type="text"
                  registerProps={register('otherText')}
                  errors={errors.otherText}
                />
              )}
              {watchForm === 'online_delivery' && (
                <TextBox
                  className="form-control"
                  placeholder={chooseOnlineDelivery}
                  type="text"
                  registerProps={register('otherText')}
                  errors={errors.otherText}
                />
              )}
            </div>
          </div>
          {(watchForm === 'ngo' || watchForm === 'facility') && (
            <div className="form-item">
              <h2>
                ဘဝတူ peer ဆီမှ အကူအညီ ဖြင့် သွေးစစ်ခြင်း ပြုလုပ်ခြင်း ဟုတ်
                ပါသလား
              </h2>
              <div className="py-2 px-3">
                <SelectBox
                  control={control}
                  className="form-control"
                  name="is_assisted"
                  rules={{ required: true }}
                  label={
                    'ဘဝတူ peer ဆီမှ အကူအညီ ဖြင့် သွေးစစ်ခြင်း ပြုလုပ်ခြင်း ဟုတ် ပါသလား'
                  }
                  options={ASSISTED_OPTIONS}
                />
              </div>
            </div>
          )}

          <div className="form-item">
            <p>သွေးအဖြေ (သင့် သွေးစစ်ဆေးမှုရလဒ်၏ ပုံကို ရွေးပေးပါ)</p>
            <div className="selector-container">
              <Row>
                <Col md="6" sm="12">
                  <div
                    onClick={() => setValue('result', 'non_reactive')}
                    className={`icon-wrapper ${
                      watchResult === 'non_reactive' ? 'selected' : ''
                    }`}
                  >
                    <img
                      src={Negative}
                      style={{ width: '100%', height: 150 }}
                    />
                    <h3>အတည်ပြုရန်မလို</h3>
                  </div>
                </Col>
                <Col md="6" sm="12">
                  <div
                    onClick={() => setValue('result', 'reactive')}
                    className={`icon-wrapper ${
                      watchResult === 'reactive' ? 'selected' : ''
                    }`}
                  >
                    <img
                      src={Positive}
                      style={{ width: '100%', height: 150 }}
                    />
                    <h3>အတည်ပြုရန်လို</h3>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="text-center py-3 px-3">
              <Button>Submit</Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SelfTestingCreate
