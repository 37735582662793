import React from 'react'

import { CardBody, CardTitle } from 'reactstrap'

export default function DetailDialog({ data, lastDate }) {
  return (
    <>
      <CardTitle className="text-center text-primary font-weight-bolder">
        Previously Registered at {data.active_org}
      </CardTitle>
      <CardBody>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Client Code</span>
          <span>{data.active_code?.pt_code}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Active Org</span>
          <span>{data.active_org}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Name</span>
          <span>{data.Name}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Date of Birth</span>
          <span>{data.Date_of_Birth}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Place of Birth</span>
          <span>{data.Place_of_Birth}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Gender</span>
          <span>{data.Gender}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Mother Name</span>
          <span>{data.Mother_Name}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Fahter Name</span>
          <span>{data.Father_Name}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Registration Place</span>
          <span>{data.Registration_Place}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Registration Date</span>
          <span>{data.Registration_Date}</span>
        </div>

        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last Screening Date</span>
          <span>{lastDate.last_screening_date}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last Confirmed Refer Date</span>
          <span>{lastDate.refer_date}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last Confirm Date</span>
          <span>{lastDate.confirm_date}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last Referal Date for ART </span>
          <span>{lastDate.refer_art_date}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last ART Initation Date</span>
          <span>{lastDate.last_artinit_date}</span>
        </div>
        <div className="mb-3 d-flex justify-content-between">
          <span className="font-weight-bolder">Last ART (Yes/NO)</span>
          <span>{lastDate.art_yes_no}</span>
        </div>
      </CardBody>
    </>
  )
}
