import React from 'react'

import Table from 'components/Table'

function List({
  headers,
  data,
  onEdit,
  title,
  buttons,
  onDelete,
  selectedData = [],
  onSelect,
  filter,
  showDetail,
  removeColumns,
}) {
  const rowWidget = React.useCallback(
    (e, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td onClick={() => showDetail(e)}>
            <span style={{ color: 'blue', cursor: 'pointer' }}>
              {e['orgname'] === 'PGK'
                ? e['linkage']['PGK']
                : e['linkage']['MPG']}
            </span>
          </td>
          <td>{e['Age']}</td>
          {Object.keys(e).map((k, i) => {
            if (!removeColumns.includes(k) && k !== 'linkage')
              return <td key={i}>{e[k]}</td>
          })}
        </tr>
      )
    },
    [removeColumns, showDetail]
  )

  if (data.length > 0) {
    headers.unshift('No', 'Client Code',"Age")
    return (
      <Table
        title={title}
        headers={headers.map((e, index) => (
          <>
            <th scope="col" key={`thead-${Math.random()}`}>
              {e.replace('_', ' ')}
            </th>
          </>
        ))}
        filter={filter}
        buttons={buttons}
        rows={data.map((e, index) => {
          return rowWidget(e, index)
        })}
      />
    )
  }

  return <></>
}

export default List
