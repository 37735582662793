import { useMemo } from 'react'

export default function FromWhereResultTable({ data }) {
  const sources = useMemo(() => {
    return [...new Set(data?.flatMap((item) => Object.keys(item.total)))]
  }, [data])
  return (
    <table
      className="table table-bordered table-sm d-none"
      id="table_fromWhere_results"
    >
      <thead>
        <tr className="text-center">
        <th className="align-middle" width={50}>
          Date</th>
          {sources?.map((source) => (
            <th className="align-middle" width={50}>
              {source}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => {
          return (
            <tr>
              <td className="align-middle">{row?.date}</td>
              {sources?.map((source) => (
                <td className="align-middle">{row?.total[source]}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
