export default function ByPlaceResultTable({data}) {
  return (
    <table
      className="table table-bordered table-sm d-none"
      id="by_place_result"
    >
      <thead>
        <tr className="text-center">
          <th className="align-middle" width={50}>
          Distribution Point Name
          </th>
          <th className="align-middle" width={50}>
            Reactive
          </th>
          <th className="align-middle" width={50}>
            Non-Reactive
          </th>
          <th className="align-middle" width={50}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => (
          <tr>
            <td className="align-middle">{row?.fromDescription}</td>
            <td className="align-middle">{row?.reactive_count}</td>
            <td className="align-middle">{row?.non_reactive_count}</td>
            <td className="align-middle">{row?.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
