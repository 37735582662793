import {  Line } from "react-chartjs-2";
import {  Card, CardBody, Col, Row } from "reactstrap";
import { lineChartConfig } from "./chartConfig";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default function LineChart({data, title, uniqueChartClassName}) {
    return (
      <Card style={{height: '400px'}}>
        <CardBody>
        <Row>
            <Col>
            <h3 className='text-center'>{title}</h3>
            </Col>

          </Row>
          <div className={`${uniqueChartClassName}`}>
            <Line
              data={data}
              options={lineChartConfig}
              plugins={[ChartDataLabels]}
            />
          </div>
        </CardBody>
      </Card>
    );
}