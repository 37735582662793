import React, { useState } from 'react'
import { useHistory } from 'react-router'
import usePatient from 'hooks/usePatient'
import List from './list'
import FilterBox from './filter'
import { capitalizeFirstLetter } from 'utli'
import useTesting from 'hooks/useTesting'
import useLinkageToCare from 'hooks/useLinkageToCare'
import DetailDialog from 'components/detailDialog'
import FullScreenLoading from 'components/FullScreenLoading'
import queryString from 'query-string'
import Button from 'components/Button'
import ReactExport from 'react-export-excel'
import dayjs from 'dayjs'

function Testing() {
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

  const [showDetail, setShowDetail] = useState(false)
  const [authorized, setAuthorized] = React.useState(true)
  const { state, fetchTestings } = useTesting()
  const { getLastDate, state: linkageState } = useLinkageToCare()
  const { fetchPatient, state: patientState } = usePatient()

  const removeColumns = React.useMemo(
    () => ['id', 'link_id', '', 'username', 'linkage', 'status','Age'],
    []
  )

  const getHeaders = React.useCallback(() => {
    let headers = []
    if (state.datas.length > 0) {
      Object.keys(state.datas[0]).forEach((k, i) => {
        headers.push(k)
      })
    }

    headers = headers.filter((e) => !removeColumns.includes(e))

    return headers
  }, [removeColumns, state.datas])
  const router = useHistory()
  React.useEffect(() => {
    const { unam } = queryString.parse(router.location.search)
    if (!unam) {
      setAuthorized(false)
      return
    }
    setAuthorized(true)
    fetchTestings(queryString.parse(router.location.search))
  }, [])

  const fileDatas = getHeaders().filter((e) => !e.includes('No'))
  fileDatas.unshift('client_Code',"Age")
  const temporaryDatas = [...state.datas]

  state.datas.map((d, i) => {
    let client_Code
    state.datas[i].orgname === 'PGK'
      ? (client_Code = d.linkage.PGK)
      : (client_Code = d.linkage.MPG)
    temporaryDatas[i] = { ...temporaryDatas[i], client_Code }
  })

  if (state.loading) {
    return <FullScreenLoading />
  }

  if (!authorized)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h1>Unauthoroized</h1>
      </div>
    )

  return (
    <>
      <List
        removeColumns={removeColumns}
        headers={getHeaders()}
        data={state.datas}
        title={'Lab Testing'}
        onDelete={(data) => {}}
        onEdit={(data) => {}}
        filter={
          <FilterBox
            headers={getHeaders()}
            onSearch={(query) => {
              const param = queryString.parse(router.location.search)
              query = { ...query, ...param }
              window.location = `/testing?&${new URLSearchParams(
                query
              ).toString()}`
            }}
          />
        }
        buttons={
          <>
            <ExcelFile
              element={<Button>Export</Button>}
              filename={`${dayjs(new Date()).format('YY/MM/DD')}_Testing`}
            >
              <ExcelSheet data={temporaryDatas} name="Linkage to care table">
                {fileDatas.map((f, i) => (
                  <ExcelColumn
                    label={capitalizeFirstLetter(f.replace('_', ' '))}
                    value={f}
                    key={i}
                  />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </>
        }
        onSelect={(e) => {}}
        showDetail={async (e) => {
          window.location = `/patient-detail/${e['linkage']['pid']}`
        }}
      />
      {patientState.data && linkageState.lastDate && (
        <DetailDialog
          modalOpen={showDetail}
          data={patientState.data}
          lastDate={linkageState.lastDate}
          onToggle={() => setShowDetail(false)}
        />
      )}
    </>
  )
}

export default Testing
