import React from 'react'
import { useHistory } from 'react-router'
import List from './list'
import FilterBox from './filter'
import useSelfTesting from 'hooks/useSelfTesting'
import FullScreenLoading from 'components/FullScreenLoading'
import queryString from 'query-string'
import Button from 'components/Button'
import ReactExport from 'react-export-excel'
import dayjs from 'dayjs'

function SelfTestingList() {
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
  const { state, fetchTestings } = useSelfTesting()

  const headers = React.useMemo(
    () => [
      'no',
      'code',
      "Gender",
      'Distribution Point',
      'Distribution Point Name',
      'Type of self-testing',
      'Key Population',
      'Result',
      'Submitted Date',
    ],
    []
  )

  const router = useHistory()

  React.useEffect(() => {
    fetchTestings(queryString.parse(router.location.search))
  }, [])



  if (state.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <List
        headers={headers}
        data={state.datas}
        title={'Self Testing'}
        filter={
          <FilterBox
            headers={headers}
            onSearch={(query) => {
     
              window.location = `/self_testing_list?&${new URLSearchParams(
                query
              ).toString()}`
            }}
          />
        }
        buttons={
          <>
            <ExcelFile
              element={<Button>Export</Button>}
              filename={`${dayjs(new Date()).format('YY/MM/DD')}_Self_Testing`}
            >
              <ExcelSheet data={state.datas} name="Linkage to care table">
                <ExcelColumn label="Code" value="code" />
                <ExcelColumn label="Gender" value="gender" />
                <ExcelColumn label="Distribution Point" value="fromWhere" />
                <ExcelColumn
                  label="Distribution Point Name"
                  value={(col) =>
                    col.fromWhere === 'other' ||  col.fromWhere === 'online_delivery'
                      ? col.otherText
                      : col.fromDescription
                  }
                />
                <ExcelColumn label="Type of self-testing" value="is_assisted" />
                <ExcelColumn label="Key Population" value="risk_group" />
                <ExcelColumn label="Result" value="result" />
                <ExcelColumn label="Submitted Date" value="created_at" />
              </ExcelSheet>
            </ExcelFile>
          </>
        }
        onSelect={(e) => {}}
      />
    </>
  )
}

export default SelfTestingList
