import React from 'react'
import './index.scss'
import { Col, Row } from 'reactstrap'

import Video from 'assets/img/icons/video.png'
import Answer from 'assets/img/icons/answer.png'
import Clinic from 'assets/img/icons/clinic.png'

function SelfTesting() {
  return (
    <div className="self-testing-container">
      <Row>
        <Col md="4" sm="12">
          <a href="">
            <div className="icon-wrapper">
              <img src={Video} className="self-testing-icon" />
              <h5>
              မိမိကိုယ်တိုင် သွေးစစ်ခြင်း မည်သို့ပြုလုပ်ရမည်ကို <br />
                ကြည့်ရှုရန်
            
              </h5>
            </div>
          </a>
        </Col>
        <Col md="4" sm="12">
          <a href="/self_testing/create">
            <div className="icon-wrapper">
              <img src={Answer} className="self-testing-icon" />
              <h5>အဖြေပို့ရန်</h5>
            </div>
          </a>
        </Col>
        <Col md="4" sm="12">
          <a href="">
            <div className="icon-wrapper">
              <img src={Clinic} className="self-testing-icon" />
              <h5>ပြသနိုင်သော ဆေးခန်းများ</h5>
            </div>
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default SelfTesting
