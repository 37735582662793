import React from 'react'
import { getData } from 'services/apiService'

const initialState = { data: {}, loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_REPORT':
      return { ...state, data: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }

    default:
      throw new Error()
  }
}

const useReport = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchReport = async (query) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(`report?${new URLSearchParams(query)}`)

    dispatch({ type: 'SET_REPORT', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
    const fetchFromWhereReport = async (query) => {
      dispatch({ type: 'SET_LOADING', payload: true })

      let response = await getData(
        `self_testing_fromWhere_report?${new URLSearchParams(query)}`
      )

      dispatch({ type: 'SET_REPORT', payload: { data: response } })
      dispatch({ type: 'SET_LOADING', payload: false })
    }

  return { fetchReport, state: state, fetchFromWhereReport }
}

export default useReport
