import Login from 'pages/Login'
import Testing from 'pages/testing/list'
import LinkageToCare from 'pages/linkage_to_care/list'
import Report from 'pages/report/list'
import PatientDetail from 'pages/patient/detail'
import SelfTesting from 'pages/self_testing'
import SelfTestingCreate from 'pages/self_testing/create'
import SelfTestingResult from 'pages/self_testing/result'
import SelfTestingView from 'pages/self_testing/view'
import SelfTestingList from 'pages/self_testing/list'
import SaleTestingDashboard from 'pages/self_testing/dashboard'
import SelfTestingReport from 'pages/self_testing/report/list'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: 'patient-detail/:id',
    name: 'patient-detail',
    icon: 'fas fa-home text-primary',
    component: PatientDetail,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing',
    name: 'self-testing',
    icon: 'fas fa-home text-primary',
    component: SelfTesting,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing/create',
    name: 'self-testing-create',
    icon: 'fas fa-home text-primary',
    component: SelfTestingCreate,
    layout: '/',
    invisible: true,
  },

  {
    path: 'self_testing/dashboard',
    name: 'self-testing-dashboard',
    icon: 'fas fa-home text-primary',
    component: SaleTestingDashboard,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing/result/:id',
    name: 'self-testing-result',
    icon: 'fas fa-home text-primary',
    component: SelfTestingResult,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing_view/:code',
    name: 'self-testing-view',
    icon: 'fas fa-home text-primary',
    component: SelfTestingView,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing_list',
    name: 'self-testing-list',
    icon: 'fas fa-home text-primary',
    component: SelfTestingList,
    layout: '/',
    invisible: true,
  },
  {
    path: 'self_testing_report',
    name: 'self-testing-report',
    icon: 'fas fa-home text-primary',
    component: SelfTestingReport,
    layout: '/',
    invisible: true,
  },

  {
    collapse: true,
    name: 'Table',
    icon: '	fa fa-file-word text-green',
    state: 'adminCollapse',
    views: [
      {
        path: 'linkageToCare',
        name: 'Referral & Linkage',
        miniName: 'OQ',
        component: LinkageToCare,
        layout: '/',
      },
      {
        path: 'testing',
        name: 'Lab Testing',
        miniName: 'OQ',
        component: Testing,
        layout: '/',
      },
      {
        path: 'report',
        name: 'Report',
        miniName: 'OQ',
        component: Report,
        layout: '/',
      },
    ],
  },
]

export default routes
