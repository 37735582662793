import React, { useEffect, useState } from 'react'
import './index.scss'
import './index.scss'
import LineChart from 'components/charts/LineChart'
import AdminNavbar from 'components/Navbars/AdminNavbar'
import BarChart from 'components/charts/BarChart'
import { stackedBarCharConfig } from 'components/charts/chartConfig'
import { Button, Col, Row } from 'reactstrap'
import { comboChartConfig } from 'components/charts/chartConfig'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import useSelfTesting from 'hooks/useSelfTesting'
import { transformedStackedBarData } from 'components/charts/chartConfig'
import { transformedLineData } from 'components/charts/chartConfig'
import { lightColors5 } from 'components/charts/chartConfig'
import { lightColors2 } from 'components/charts/chartConfig'
import SelectBox from 'components/SelectBox'
import { FROM_WHERE_OPTIONS } from 'variables/general'
import { useForm } from 'react-hook-form'
import { transformComboChartData } from 'components/charts/chartConfig'
import FullScreenLoading from 'components/FullScreenLoading'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import dayjs from 'dayjs'
import SubmittedResultTable from './submitted_result_table'
import FromWhereResultTable from './fromWhere_result_table'
import ReactiveNonReactiveTable from './reactive_nonreactive_result_table'
import ByPlaceResultTable from './by_place_result_table'
import { RED } from 'components/charts/chartConfig'
import { transformedBarChartData } from 'components/charts/chartConfig'
import ReactiveTable from './reactive_result_table'

function SaleTestingDashboard() {
  const [dates, setDates] = useState(null)

  const router = useHistory()
  const { control, handleSubmit } = useForm()

  const {
    state: { data: testingData, loading },
    fetchSelectTestingReport,
  } = useSelfTesting()
  useEffect(() => {
    async function loadData() {
      await fetchSelectTestingReport(queryString.parse(router.location.search))
    }
    loadData()
  }, [router.location.search])

  const onSubmit = (data) => {
    let queryString = '/self_testing/dashboard'
    let queryParams = []

    if (data.fromWhere) {
      queryParams.push('fromWhere=' + encodeURIComponent(data.fromWhere))
    }

    if (dates !== null) {
      queryParams.push('start_date=' + encodeURIComponent(dates[0]))
      queryParams.push('end_date=' + encodeURIComponent(dates[1]))
    }

    if (queryParams.length > 0) {
      queryString += '?' + queryParams.join('&')
    }

    router.push(queryString)
  }

  if (loading) {
    return <FullScreenLoading />
  }
  return (
    <div>
      <AdminNavbar
        toggleSidenav={true}
        sidenavOpen={() => {}}
        brandText={''}
        content={
          <div
            style={{ width: '100%' }}
            className="pb-2 d-flex align-items-center justify-content-between"
          >
            <span
              style={{ fontSize: 30, color: 'black', cursor: 'pointer' }}
              onClick={() => {
                window.history.go(-1)
              }}
            >
              🔙
            </span>
            <h3 style={{ flex: 1, textAlign: 'center' }}>
              {' '}
              HIV Self-Testing from Secondary Distribution Dashboard
            </h3>
            <div className="mt-2 mb-2 button-container">
              <Button
                onClick={() => {
                  window.location = '/self_testing/dashboard'
                }}
              >
                Refresh
              </Button>
            </div>
          </div>
        }
      />
      <Row className="m-4 d-flex justify-content-center align-items-center">
        <Col md={6}>
          <DateRangePicker onDateChange={(dates) => setDates(dates)} />
        </Col>

        <Col
          md={5}
          className="d-flex align-items-center justify-content-center"
          style={{
            gap: '10px',
          }}
        >
          <h1 className="p-0 m-0">Distribution Point:</h1>
          <div className="flex-grow-1">
            <SelectBox
              control={control}
              className="form-control"
              name="fromWhere"
              rules={{ required: false }}
              label={'Distribution Point'}
              options={FROM_WHERE_OPTIONS}
            />
          </div>
        </Col>
        <Col md={1}>
          <Button
            size="sm"
            color="success"
            style={{ height: 35 }}
            onClick={handleSubmit(onSubmit)}
          >
            <i className="fa fa-search" />
          </Button>
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-around p-2"
        style={{ gap: '20px' }}
      >
        <Col md={3} className="d-flex bg-blue" style={{ height: '70px' }}>
          <div className="d-flex justify-content-center align-items-center mr-4">
            <i className="fas fa-home text-white fa-3x" />
          </div>
          <div className="d-flex flex-column py-2">
            <h3 className="p-0 m-0 text-white">Total # of self-testing</h3>
            <h1 className="text-center text-white">
              {testingData?.total_count}
            </h1>
          </div>
        </Col>
        <Col
          md={3}
          className="d-flex"
          style={{ height: '70px', backgroundColor: 'green' }}
        >
          <div className="d-flex justify-content-center align-items-center mr-4">
            <i className="fas fa-home text-white fa-3x" />
          </div>
          <div className="d-flex flex-column py-2">
            <h3 className="p-0 m-0 text-white">Total # of Non-Reactive</h3>
            <h1 className="text-center text-white">
              {testingData?.reactive_count}
            </h1>
          </div>
        </Col>
        <Col md={3} className="d-flex bg-red" style={{ height: '70px' }}>
          <div className="d-flex justify-content-center align-items-center mr-4">
            <i className="fas fa-home text-white fa-3x" />
          </div>
          <div className="d-flex flex-column py-2">
            <h3 className="p-0 m-0 text-white">Total # of Reactive</h3>
            <h1 className="text-center text-white">
              {testingData?.non_reactive_count}
            </h1>
          </div>
        </Col>
      </Row>
      <Row className="p-3 my-6">
        <Col md={6}>
          <div className="d-flex justify-content-end mb-3">
            <ReactHTMLTableToExcel
              id="table_submitted_results-button"
              className="btn btn-secondary"
              table="table_submitted_results"
              filename={`# of HIV self testing results submitted-${dayjs(
                new Date()
              ).format('DD/MM/YY hh:ss')}`}
              sheet="tablexls123"
              buttonText="Export"
            />
          </div>
          <LineChart
            data={transformedLineData(testingData?.submitted_results)}
            title={'# of HIV self testing results and reactive'}
          />
          <SubmittedResultTable data={testingData?.submitted_results} />
        </Col>

        <Col md={6}>
          <div className="d-flex justify-content-end mb-3">
            <ReactHTMLTableToExcel
              id="table_fromWhere_results-button"
              className="btn btn-secondary"
              table="table_fromWhere_results"
              filename={`# of self testing results received (By Distribution Points)-${dayjs(
                new Date()
              ).format('DD/MM/YY hh:ss')}`}
              sheet="tablexls123"
              buttonText="Export"
            />
          </div>
          <BarChart
            data={transformedStackedBarData(
              testingData?.fromWhere_results,
              lightColors5
            )}
            title={
              '# of self testing results received (By Distribution Points)'
            }
            option={stackedBarCharConfig}
          />
          <FromWhereResultTable data={testingData?.fromWhere_results} />
        </Col>
      </Row>
      <Row className="p-3 my-6">
        <Col md={6}>
          <div className="d-flex justify-content-end mb-3">
            <ReactHTMLTableToExcel
              id="table_reactiveNonReactive_results-button"
              className="btn btn-secondary"
              table="table_reactiveNonReactive_results"
              filename={`# of self testing results received (By Screening Results)-${dayjs(
                new Date()
              ).format('DD/MM/YY hh:ss')}`}
              sheet="tablexls123"
              buttonText="Export"
            />
          </div>
          <BarChart
            data={transformedStackedBarData(
              testingData?.reactiveNonReactive_results,
              lightColors2
            )}
            title={'# of self testing results received (By Screening Results)'}
            option={stackedBarCharConfig}
          />
          <ReactiveNonReactiveTable
            data={testingData?.reactiveNonReactive_results}
          />
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end mb-3">
            <ReactHTMLTableToExcel
              id="by_place_result-button"
              className="btn btn-secondary"
              table="by_place_result"
              filename={`Self Testing By Place-${dayjs(new Date()).format(
                'DD/MM/YY hh:ss'
              )}`}
              sheet="tablexls123"
              buttonText="Export"
            />
          </div>
          <BarChart
            data={transformComboChartData(testingData?.by_place_result)}
            title={'Self Testing By Place'}
            option={comboChartConfig}
          />
          <ByPlaceResultTable data={testingData?.by_place_result} />
        </Col>
      </Row>
      <Row className="p-3 my-6">
        <Col md={6}>
          <div className="d-flex justify-content-end mb-3">
            <ReactHTMLTableToExcel
              id="table_reactive_results-button"
              className="btn btn-secondary"
              table="table_reactive_results"
              filename={`# of self testing reactive results (By Screening Results)-${dayjs(
                new Date()
              ).format('DD/MM/YY hh:ss')}`}
              sheet="tablexls123"
              buttonText="Export"
            />
          </div>
          <BarChart
            data={transformedBarChartData(
              testingData?.reactiveNonReactive_results,
              RED,
              'reactive'
            )}
            title={'# of self testing results received (By Screening Results)'}
          />
          <ReactiveTable
            data={testingData?.reactiveNonReactive_results}
          />
        </Col>
      </Row>
    </div>
  )
}

export default SaleTestingDashboard
