import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import AdminNavbar from 'components/Navbars/AdminNavbar'
import ReportTable from './ReportTable'
import '../../../../styles/reportTableStyle.css'
import { CardBody, Card, CardTitle } from 'reactstrap'
import FilterBox from './filter'
import useReport from 'hooks/useReport'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import FullScreenLoading from 'components/FullScreenLoading'
import dayjs from 'dayjs'
import useSelfTestingOptions from 'hooks/useSelfTestingOptions'

function SelfTestingReport() {
  const [dates, setDates] = useState(null)
  const tableRef = React.useRef(null)
  const { state, fetchFromWhereReport } = useReport()
  const router = useHistory()
  const { state: options, fetchOptions } = useSelfTestingOptions()

  React.useEffect(() => {
    async function loadData() {
      await fetchFromWhereReport(queryString.parse(router.location.search))
    }
    loadData()
  }, [router.location.search])

  useEffect(() => {
    async function loadData() {
      await fetchOptions()
    }
    loadData()
  }, [fetchOptions])

  const onSubmit = (data) => {
    let queryString = '/self_testing_report'
    let queryParams = []

    if (data.fromWhere) {
      queryParams.push('fromWhere=' + encodeURIComponent(data.fromWhere))
    }
    if (data.fromDescription) {
      queryParams.push(
        'fromDescription=' + encodeURIComponent(data.fromDescription)
      )
    }

    if (dates !== null) {
      queryParams.push('start_date=' + encodeURIComponent(dates[0]))
      queryParams.push('end_date=' + encodeURIComponent(dates[1]))
    }

    if (queryParams.length > 0) {
      queryString += '?' + queryParams.join('&')
    }

    router.push(queryString)
  }

  if (state.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      <AdminNavbar
        toggleSidenav={true}
        sidenavOpen={() => {}}
        brandText={''}
        content={
          <div
            style={{ width: '100%' }}
            className="pb-2 d-flex align-items-center justify-content-between"
          >
            <span
              style={{ fontSize: 30, color: 'black', cursor: 'pointer' }}
              onClick={() => {
                window.history.go(-1)
              }}
            >
              🔙
            </span>
            <h3 style={{ flex: 1, textAlign: 'center' }}>Report</h3>
            <div className="mt-2 mb-2 button-container">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-secondary"
                table="table-to-xls"
                filename={`report-${dayjs(new Date()).format(
                  'DD/MM/YY hh:ss'
                )}`}
                sheet="tablexls123"
                buttonText="Export"
              />
            </div>
          </div>
        }
      />
      <div className=" d-flex justify-content-center mt-3">
        <div className="col-12">
          <Card>
            <CardTitle>
              <FilterBox
                onDateChange={(dates) => setDates(dates)}
                onClick={onSubmit}
                options={options}
              />
            </CardTitle>
            {/* {start_date && end_date && ( */}
            <CardBody style={{ maxWidth: '100%', overflowX: 'auto' }}>
              <ReportTable data={state.data} ref={tableRef} />
            </CardBody>
            {/* )} */}
          </Card>
        </div>
      </div>
    </>
  )
}
export default SelfTestingReport
