import React from 'react'

function ReportTable({ data }) {
  const age0_9 = data['0_9']
  const age10_14 = data['10_14']
  const age15_19 = data['15_19']
  const age20_24 = data['20_24']
  const age25_49 = data['25_49']
  const age50 = data['50']
  const bottom = data['bottom']

  return (
    <>
      <table className="table table-bordered table-sm" id="table-to-xls">
        <thead>
          <tr className="text-center">
            <th rowSpan={3} className="align-middle" width={50}>
              Se
            </th>
            <th rowSpan={3} className="align-middle" width={200}>
              Age GAP <br /> (year)
            </th>
            <th rowSpan={3} colSpan={5} className="align-middle">
              HIV test
            </th>
            <th colSpan={3}>Sex Workers</th>
            <th colSpan={3}>Client of SW</th>
            <th rowSpan={3} colSpan={2} className="align-middle">
              MSM
            </th>
            <th rowSpan={2} colSpan={2} className="align-middle">
              TGW
            </th>
            <th colSpan={3}>PWID</th>
            <th colSpan={3}>PWUD</th>
            <th colSpan={3}>Partner of KP</th>
            <th colSpan={3}>Partner of PLHIV</th>
            <th colSpan={3}>Others</th>
          </tr>
          <tr className="text-center">
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
            <th>M</th>
            <th>F</th>
            <th>TG</th>
          </tr>
        </thead>
        <tbody>
          {/* first row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              1
            </td>
            <td rowSpan={3} className="align-middle">
              0-9
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age0_9?.HIV_Tested.SW_M}</td>
            <td>{age0_9?.HIV_Tested.SW_F}</td>
            <td>{age0_9?.HIV_Tested.SW_TG}</td>
            <td>{age0_9?.HIV_Tested.CSW_M}</td>
            <td>{age0_9?.HIV_Tested.CSW_F}</td>
            <td>{age0_9?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age0_9?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age0_9?.HIV_Tested.TGW}</td>
            <td>{age0_9?.HIV_Tested.PWID_M}</td>
            <td>{age0_9?.HIV_Tested.PWID_F}</td>
            <td>{age0_9?.HIV_Tested.PWID_TG}</td>
            <td>{age0_9?.HIV_Tested.PWUD_M}</td>
            <td>{age0_9?.HIV_Tested.PWUD_F}</td>
            <td>{age0_9?.HIV_Tested.PWUD_TG}</td>
            <td>{age0_9?.HIV_Tested.PKP_M}</td>
            <td>{age0_9?.HIV_Tested.PKP_F}</td>
            <td>{age0_9?.HIV_Tested.PKP_TG}</td>
            <td>{age0_9?.HIV_Tested.PLHIV_M}</td>
            <td>{age0_9?.HIV_Tested.PLHIV_F}</td>
            <td>{age0_9?.HIV_Tested.PLHIV_TG}</td>
            <td>{age0_9?.HIV_Tested.other_M}</td>
            <td>{age0_9?.HIV_Tested.other_F}</td>
            <td>{age0_9?.HIV_Tested.PLHIV_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age0_9?.Post_Test.SW_M}</td>
            <td>{age0_9?.Post_Test.SW_F}</td>
            <td>{age0_9?.Post_Test.SW_TG}</td>
            <td>{age0_9?.Post_Test.CSW_M}</td>
            <td>{age0_9?.Post_Test.CSW_F}</td>
            <td>{age0_9?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age0_9?.Post_Test.MSM}</td>
            <td colSpan={2}>{age0_9?.Post_Test.TGW}</td>
            <td>{age0_9?.Post_Test.PWID_M}</td>
            <td>{age0_9?.Post_Test.PWID_F}</td>
            <td>{age0_9?.Post_Test.PWID_TG}</td>
            <td>{age0_9?.Post_Test.PWUD_M}</td>
            <td>{age0_9?.Post_Test.PWUD_F}</td>
            <td>{age0_9?.Post_Test.PWUD_TG}</td>
            <td>{age0_9?.Post_Test.PKP_M}</td>
            <td>{age0_9?.Post_Test.PKP_F}</td>
            <td>{age0_9?.Post_Test.PKP_TG}</td>
            <td>{age0_9?.Post_Test.PLHIV_M}</td>
            <td>{age0_9?.Post_Test.PLHIV_F}</td>
            <td>{age0_9?.Post_Test.PLHIV_TG}</td>
            <td>{age0_9?.Post_Test.other_M}</td>
            <td>{age0_9?.Post_Test.other_F}</td>
            <td>{age0_9?.Post_Test.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age0_9?.HIV_Positive.SW_M}</td>
            <td>{age0_9?.HIV_Positive.SW_F}</td>
            <td>{age0_9?.HIV_Positive.SW_TG}</td>
            <td>{age0_9?.HIV_Positive.CSW_M}</td>
            <td>{age0_9?.HIV_Positive.CSW_F}</td>
            <td>{age0_9?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age0_9?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age0_9?.HIV_Positive.TGW}</td>
            <td>{age0_9?.HIV_Positive.PWID_M}</td>
            <td>{age0_9?.HIV_Positive.PWID_F}</td>
            <td>{age0_9?.HIV_Positive.PWID_TG}</td>
            <td>{age0_9?.HIV_Positive.PWUD_M}</td>
            <td>{age0_9?.HIV_Positive.PWUD_F}</td>
            <td>{age0_9?.HIV_Positive.PWUD_TG}</td>
            <td>{age0_9?.HIV_Positive.PKP_M}</td>
            <td>{age0_9?.HIV_Positive.PKP_F}</td>
            <td>{age0_9?.HIV_Positive.PKP_TG}</td>
            <td>{age0_9?.HIV_Positive.PLHIV_M}</td>
            <td>{age0_9?.HIV_Positive.PLHIV_F}</td>
            <td>{age0_9?.HIV_Positive.PLHIV_TG}</td>
            <td>{age0_9?.HIV_Positive.other_M}</td>
            <td>{age0_9?.HIV_Positive.other_F}</td>
            <td>{age0_9?.HIV_Positive.other_TG}</td>
          </tr>
          {/* first row */}

          {/* second row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              2
            </td>
            <td rowSpan={3} className="align-middle">
              10-14
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age10_14?.HIV_Tested.SW_M}</td>
            <td>{age10_14?.HIV_Tested.SW_F}</td>
            <td>{age10_14?.HIV_Tested.SW_TG}</td>
            <td>{age10_14?.HIV_Tested.CSW_M}</td>
            <td>{age10_14?.HIV_Tested.CSW_F}</td>
            <td>{age10_14?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age10_14?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age10_14?.HIV_Tested.TGW}</td>
            <td>{age10_14?.HIV_Tested.PWID_M}</td>
            <td>{age10_14?.HIV_Tested.PWID_F}</td>
            <td>{age10_14?.HIV_Tested.PWID_TG}</td>
            <td>{age10_14?.HIV_Tested.PWUD_M}</td>
            <td>{age10_14?.HIV_Tested.PWUD_F}</td>
            <td>{age10_14?.HIV_Tested.PWUD_TG}</td>
            <td>{age10_14?.HIV_Tested.PKP_M}</td>
            <td>{age10_14?.HIV_Tested.PKP_F}</td>
            <td>{age10_14?.HIV_Tested.PKP_TG}</td>
            <td>{age10_14?.HIV_Tested.PLHIV_M}</td>
            <td>{age10_14?.HIV_Tested.PLHIV_F}</td>
            <td>{age10_14?.HIV_Tested.PLHIV_TG}</td>
            <td>{age10_14?.HIV_Tested.other_M}</td>
            <td>{age10_14?.HIV_Tested.other_F}</td>
            <td>{age10_14?.HIV_Tested.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age10_14?.Post_Test.SW_M}</td>
            <td>{age10_14?.Post_Test.SW_F}</td>
            <td>{age10_14?.Post_Test.SW_TG}</td>
            <td>{age10_14?.Post_Test.CSW_M}</td>
            <td>{age10_14?.Post_Test.CSW_F}</td>
            <td>{age10_14?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age10_14?.Post_Test.MSM}</td>
            <td colSpan={2}>{age10_14?.Post_Test.TGW}</td>
            <td>{age10_14?.Post_Test.PWID_M}</td>
            <td>{age10_14?.Post_Test.PWID_F}</td>
            <td>{age10_14?.Post_Test.PWID_TG}</td>
            <td>{age10_14?.Post_Test.PWUD_M}</td>
            <td>{age10_14?.Post_Test.PWUD_F}</td>
            <td>{age10_14?.Post_Test.PWUD_TG}</td>
            <td>{age10_14?.Post_Test.PKP_M}</td>
            <td>{age10_14?.Post_Test.PKP_F}</td>
            <td>{age10_14?.Post_Test.PKP_TG}</td>
            <td>{age10_14?.Post_Test.PLHIV_M}</td>
            <td>{age10_14?.Post_Test.PLHIV_F}</td>
            <td>{age10_14?.Post_Test.PLHIV_TG}</td>
            <td>{age10_14?.Post_Test.other_M}</td>
            <td>{age10_14?.Post_Test.other_F}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age10_14?.HIV_Positive.SW_M}</td>
            <td>{age10_14?.HIV_Positive.SW_F}</td>
            <td>{age10_14?.HIV_Positive.SW_TG}</td>
            <td>{age10_14?.HIV_Positive.CSW_M}</td>
            <td>{age10_14?.HIV_Positive.CSW_F}</td>
            <td>{age10_14?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age10_14?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age10_14?.HIV_Positive.TGW}</td>
            <td>{age10_14?.HIV_Positive.PWID_M}</td>
            <td>{age10_14?.HIV_Positive.PWID_F}</td>
            <td>{age10_14?.HIV_Positive.PWID_TG}</td>
            <td>{age10_14?.HIV_Positive.PWUD_M}</td>
            <td>{age10_14?.HIV_Positive.PWUD_F}</td>
            <td>{age10_14?.HIV_Positive.PWUD_TG}</td>
            <td>{age10_14?.HIV_Positive.PKP_M}</td>
            <td>{age10_14?.HIV_Positive.PKP_F}</td>
            <td>{age10_14?.HIV_Positive.PKP_TG}</td>
            <td>{age10_14?.HIV_Positive.PLHIV_M}</td>
            <td>{age10_14?.HIV_Positive.PLHIV_F}</td>
            <td>{age10_14?.HIV_Positive.PLHIV_TG}</td>
            <td>{age10_14?.HIV_Positive.other_M}</td>
            <td>{age10_14?.HIV_Positive.other_F}</td>
            <td>{age10_14?.HIV_Positive.other_TG}</td>
          </tr>
          {/* second row */}

          {/* third row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              3
            </td>
            <td rowSpan={3} className="align-middle">
              15-19
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age15_19?.HIV_Tested.SW_M}</td>
            <td>{age15_19?.HIV_Tested.SW_F}</td>
            <td>{age15_19?.HIV_Tested.SW_TG}</td>
            <td>{age15_19?.HIV_Tested.CSW_M}</td>
            <td>{age15_19?.HIV_Tested.CSW_F}</td>
            <td>{age15_19?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age15_19?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age15_19?.HIV_Tested.TGW}</td>
            <td>{age15_19?.HIV_Tested.PWID_M}</td>
            <td>{age15_19?.HIV_Tested.PWID_F}</td>
            <td>{age15_19?.HIV_Tested.PWID_TG}</td>
            <td>{age15_19?.HIV_Tested.PWUD_M}</td>
            <td>{age15_19?.HIV_Tested.PWUD_F}</td>
            <td>{age15_19?.HIV_Tested.PWUD_TG}</td>
            <td>{age15_19?.HIV_Tested.PKP_M}</td>
            <td>{age15_19?.HIV_Tested.PKP_F}</td>
            <td>{age15_19?.HIV_Tested.PKP_TG}</td>
            <td>{age15_19?.HIV_Tested.PLHIV_M}</td>
            <td>{age15_19?.HIV_Tested.PLHIV_F}</td>
            <td>{age15_19?.HIV_Tested.PLHIV_TG}</td>
            <td>{age15_19?.HIV_Tested.other_M}</td>
            <td>{age15_19?.HIV_Tested.other_F}</td>
            <td>{age15_19?.HIV_Tested.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age15_19?.Post_Test.SW_M}</td>
            <td>{age15_19?.Post_Test.SW_F}</td>
            <td>{age15_19?.Post_Test.SW_TG}</td>
            <td>{age15_19?.Post_Test.CSW_M}</td>
            <td>{age15_19?.Post_Test.CSW_F}</td>
            <td>{age15_19?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age15_19?.Post_Test.MSM}</td>
            <td colSpan={2}>{age15_19?.Post_Test.TGW}</td>
            <td>{age15_19?.Post_Test.PWID_M}</td>
            <td>{age15_19?.Post_Test.PWID_F}</td>
            <td>{age15_19?.Post_Test.PWID_TG}</td>
            <td>{age15_19?.Post_Test.PWUD_M}</td>
            <td>{age15_19?.Post_Test.PWUD_F}</td>
            <td>{age15_19?.Post_Test.PWUD_TG}</td>
            <td>{age15_19?.Post_Test.PKP_M}</td>
            <td>{age15_19?.Post_Test.PKP_F}</td>
            <td>{age15_19?.Post_Test.PKP_TG}</td>
            <td>{age15_19?.Post_Test.PLHIV_M}</td>
            <td>{age15_19?.Post_Test.PLHIV_F}</td>
            <td>{age15_19?.Post_Test.PLHIV_TG}</td>
            <td>{age15_19?.Post_Test.other_M}</td>
            <td>{age15_19?.Post_Test.other_F}</td>
            <td>{age15_19?.Post_Test.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age15_19?.HIV_Positive.SW_M}</td>
            <td>{age15_19?.HIV_Positive.SW_F}</td>
            <td>{age15_19?.HIV_Positive.SW_TG}</td>
            <td>{age15_19?.HIV_Positive.CSW_M}</td>
            <td>{age15_19?.HIV_Positive.CSW_F}</td>
            <td>{age15_19?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age15_19?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age15_19?.HIV_Positive.TGW}</td>
            <td>{age15_19?.HIV_Positive.PWID_M}</td>
            <td>{age15_19?.HIV_Positive.PWID_F}</td>
            <td>{age15_19?.HIV_Positive.PWID_TG}</td>
            <td>{age15_19?.HIV_Positive.PWUD_M}</td>
            <td>{age15_19?.HIV_Positive.PWUD_F}</td>
            <td>{age15_19?.HIV_Positive.PWUD_TG}</td>
            <td>{age15_19?.HIV_Positive.PKP_M}</td>
            <td>{age15_19?.HIV_Positive.PKP_F}</td>
            <td>{age15_19?.HIV_Positive.PKP_TG}</td>
            <td>{age15_19?.HIV_Positive.PLHIV_M}</td>
            <td>{age15_19?.HIV_Positive.PLHIV_F}</td>
            <td>{age15_19?.HIV_Positive.PLHIV_TG}</td>
            <td>{age15_19?.HIV_Positive.other_M}</td>
            <td>{age15_19?.HIV_Positive.other_F}</td>
            <td>{age15_19?.HIV_Positive.other_TG}</td>
          </tr>
          {/* third row */}

          {/* fourth row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              4
            </td>
            <td rowSpan={3} className="align-middle">
              20-24
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age20_24?.HIV_Tested.SW_M}</td>
            <td>{age20_24?.HIV_Tested.SW_F}</td>
            <td>{age20_24?.HIV_Tested.SW_TG}</td>
            <td>{age20_24?.HIV_Tested.CSW_M}</td>
            <td>{age20_24?.HIV_Tested.CSW_F}</td>
            <td>{age20_24?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age20_24?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age20_24?.HIV_Tested.TGW}</td>
            <td>{age20_24?.HIV_Tested.PWID_M}</td>
            <td>{age20_24?.HIV_Tested.PWID_F}</td>
            <td>{age20_24?.HIV_Tested.PWID_TG}</td>
            <td>{age20_24?.HIV_Tested.PWUD_M}</td>
            <td>{age20_24?.HIV_Tested.PWUD_F}</td>
            <td>{age20_24?.HIV_Tested.PWUD_TG}</td>
            <td>{age20_24?.HIV_Tested.PKP_M}</td>
            <td>{age20_24?.HIV_Tested.PKP_F}</td>
            <td>{age20_24?.HIV_Tested.PKP_TG}</td>
            <td>{age20_24?.HIV_Tested.PLHIV_M}</td>
            <td>{age20_24?.HIV_Tested.PLHIV_F}</td>
            <td>{age20_24?.HIV_Tested.PLHIV_TG}</td>
            <td>{age20_24?.HIV_Tested.other_M}</td>
            <td>{age20_24?.HIV_Tested.other_F}</td>
            <td>{age20_24?.HIV_Tested.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age20_24?.Post_Test.SW_M}</td>
            <td>{age20_24?.Post_Test.SW_F}</td>
            <td>{age20_24?.Post_Test.SW_TG}</td>
            <td>{age20_24?.Post_Test.CSW_M}</td>
            <td>{age20_24?.Post_Test.CSW_F}</td>
            <td>{age20_24?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age20_24?.Post_Test.MSM}</td>
            <td colSpan={2}>{age20_24?.Post_Test.TGW}</td>
            <td>{age20_24?.Post_Test.PWID_M}</td>
            <td>{age20_24?.Post_Test.PWID_F}</td>
            <td>{age20_24?.Post_Test.PWID_TG}</td>
            <td>{age20_24?.Post_Test.PWUD_M}</td>
            <td>{age20_24?.Post_Test.PWUD_F}</td>
            <td>{age20_24?.Post_Test.PWUD_TG}</td>
            <td>{age20_24?.Post_Test.PKP_M}</td>
            <td>{age20_24?.Post_Test.PKP_F}</td>
            <td>{age20_24?.Post_Test.PKP_TG}</td>
            <td>{age20_24?.Post_Test.PLHIV_M}</td>
            <td>{age20_24?.Post_Test.PLHIV_F}</td>
            <td>{age20_24?.Post_Test.PLHIV_TG}</td>
            <td>{age20_24?.Post_Test.other_M}</td>
            <td>{age20_24?.Post_Test.other_F}</td>
            <td>{age20_24?.Post_Test.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age20_24?.HIV_Positive.SW_M}</td>
            <td>{age20_24?.HIV_Positive.SW_F}</td>
            <td>{age20_24?.HIV_Positive.SW_TG}</td>
            <td>{age20_24?.HIV_Positive.CSW_M}</td>
            <td>{age20_24?.HIV_Positive.CSW_F}</td>
            <td>{age20_24?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age20_24?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age20_24?.HIV_Positive.TGW}</td>
            <td>{age20_24?.HIV_Positive.PWID_M}</td>
            <td>{age20_24?.HIV_Positive.PWID_F}</td>
            <td>{age20_24?.HIV_Positive.PWID_TG}</td>
            <td>{age20_24?.HIV_Positive.PWUD_M}</td>
            <td>{age20_24?.HIV_Positive.PWUD_F}</td>
            <td>{age20_24?.HIV_Positive.PWUD_TG}</td>
            <td>{age20_24?.HIV_Positive.PKP_M}</td>
            <td>{age20_24?.HIV_Positive.PKP_F}</td>
            <td>{age20_24?.HIV_Positive.PKP_TG}</td>
            <td>{age20_24?.HIV_Positive.PLHIV_M}</td>
            <td>{age20_24?.HIV_Positive.PLHIV_F}</td>
            <td>{age20_24?.HIV_Positive.PLHIV_TG}</td>
            <td>{age20_24?.HIV_Positive.other_M}</td>
            <td>{age20_24?.HIV_Positive.other_F}</td>
            <td>{age20_24?.HIV_Positive.other_TG}</td>
          </tr>
          {/* fourth row */}

          {/* fifth row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              5
            </td>
            <td rowSpan={3} className="align-middle">
              25-49
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age25_49?.HIV_Tested.SW_M}</td>
            <td>{age25_49?.HIV_Tested.SW_F}</td>
            <td>{age25_49?.HIV_Tested.SW_TG}</td>
            <td>{age25_49?.HIV_Tested.CSW_M}</td>
            <td>{age25_49?.HIV_Tested.CSW_F}</td>
            <td>{age25_49?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age25_49?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age25_49?.HIV_Tested.TGW}</td>
            <td>{age25_49?.HIV_Tested.PWID_M}</td>
            <td>{age25_49?.HIV_Tested.PWID_F}</td>
            <td>{age25_49?.HIV_Tested.PWID_TG}</td>
            <td>{age25_49?.HIV_Tested.PWUD_M}</td>
            <td>{age25_49?.HIV_Tested.PWUD_F}</td>
            <td>{age25_49?.HIV_Tested.PWUD_TG}</td>
            <td>{age25_49?.HIV_Tested.PKP_M}</td>
            <td>{age25_49?.HIV_Tested.PKP_F}</td>
            <td>{age25_49?.HIV_Tested.PKP_TG}</td>
            <td>{age25_49?.HIV_Tested.PLHIV_M}</td>
            <td>{age25_49?.HIV_Tested.PLHIV_F}</td>
            <td>{age25_49?.HIV_Tested.PLHIV_TG}</td>
            <td>{age25_49?.HIV_Tested.other_M}</td>
            <td>{age25_49?.HIV_Tested.other_F}</td>
            <td>{age25_49?.HIV_Tested.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age25_49?.Post_Test.SW_M}</td>
            <td>{age25_49?.Post_Test.SW_F}</td>
            <td>{age25_49?.Post_Test.SW_TG}</td>
            <td>{age25_49?.Post_Test.CSW_M}</td>
            <td>{age25_49?.Post_Test.CSW_F}</td>
            <td>{age25_49?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age25_49?.Post_Test.MSM}</td>
            <td colSpan={2}>{age25_49?.Post_Test.TGW}</td>
            <td>{age25_49?.Post_Test.PWID_M}</td>
            <td>{age25_49?.Post_Test.PWID_F}</td>
            <td>{age25_49?.Post_Test.PWID_TG}</td>
            <td>{age25_49?.Post_Test.PWUD_M}</td>
            <td>{age25_49?.Post_Test.PWUD_F}</td>
            <td>{age25_49?.Post_Test.PWUD_TG}</td>
            <td>{age25_49?.Post_Test.PKP_M}</td>
            <td>{age25_49?.Post_Test.PKP_F}</td>
            <td>{age25_49?.Post_Test.PKP_TG}</td>
            <td>{age25_49?.Post_Test.PLHIV_M}</td>
            <td>{age25_49?.Post_Test.PLHIV_F}</td>
            <td>{age25_49?.Post_Test.PLHIV_TG}</td>
            <td>{age25_49?.Post_Test.other_M}</td>
            <td>{age25_49?.Post_Test.other_F}</td>
            <td>{age25_49?.Post_Test.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age25_49?.HIV_Positive.SW_M}</td>
            <td>{age25_49?.HIV_Positive.SW_F}</td>
            <td>{age25_49?.HIV_Positive.SW_TG}</td>
            <td>{age25_49?.HIV_Positive.CSW_M}</td>
            <td>{age25_49?.HIV_Positive.CSW_F}</td>
            <td>{age25_49?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age25_49?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age25_49?.HIV_Positive.TGW}</td>
            <td>{age25_49?.HIV_Positive.PWID_M}</td>
            <td>{age25_49?.HIV_Positive.PWID_F}</td>
            <td>{age25_49?.HIV_Positive.PWID_TG}</td>
            <td>{age25_49?.HIV_Positive.PWUD_M}</td>
            <td>{age25_49?.HIV_Positive.PWUD_F}</td>
            <td>{age25_49?.HIV_Positive.PWUD_TG}</td>
            <td>{age25_49?.HIV_Positive.PKP_M}</td>
            <td>{age25_49?.HIV_Positive.PKP_F}</td>
            <td>{age25_49?.HIV_Positive.PKP_TG}</td>
            <td>{age25_49?.HIV_Positive.PLHIV_M}</td>
            <td>{age25_49?.HIV_Positive.PLHIV_F}</td>
            <td>{age25_49?.HIV_Positive.PLHIV_TG}</td>
            <td>{age25_49?.HIV_Positive.other_M}</td>
            <td>{age25_49?.HIV_Positive.other_F}</td>
            <td>{age25_49?.HIV_Positive.other_TG}</td>
          </tr>
          {/* fifth row */}
          {/* sixth row */}
          <tr className="text-center">
            <td rowSpan={3} className="align-middle">
              6
            </td>
            <td rowSpan={3} className="align-middle">
              &gt;=50
            </td>
            <td colSpan={5} className="text-left">
              HIV Tested
            </td>
            <td>{age50?.HIV_Tested.SW_M}</td>
            <td>{age50?.HIV_Tested.SW_F}</td>
            <td>{age50?.HIV_Tested.SW_TG}</td>
            <td>{age50?.HIV_Tested.CSW_M}</td>
            <td>{age50?.HIV_Tested.CSW_F}</td>
            <td>{age50?.HIV_Tested.CSW_TG}</td>
            <td colSpan={2}>{age50?.HIV_Tested.MSM}</td>
            <td colSpan={2}>{age50?.HIV_Tested.TGW}</td>
            <td>{age50?.HIV_Tested.PWID_M}</td>
            <td>{age50?.HIV_Tested.PWID_F}</td>
            <td>{age50?.HIV_Tested.PWID_TG}</td>
            <td>{age50?.HIV_Tested.PWUD_M}</td>
            <td>{age50?.HIV_Tested.PWUD_F}</td>
            <td>{age50?.HIV_Tested.PWUD_TG}</td>
            <td>{age50?.HIV_Tested.PKP_M}</td>
            <td>{age50?.HIV_Tested.PKP_F}</td>
            <td>{age50?.HIV_Tested.PKP_TG}</td>
            <td>{age50?.HIV_Tested.PLHIV_M}</td>
            <td>{age50?.HIV_Tested.PLHIV_F}</td>
            <td>{age50?.HIV_Tested.PLHIV_TG}</td>
            <td>{age50?.HIV_Tested.other_M}</td>
            <td>{age50?.HIV_Tested.other_F}</td>
            <td>{age50?.HIV_Tested.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              Post-test
            </td>
            <td>{age50?.Post_Test.SW_M}</td>
            <td>{age50?.Post_Test.SW_F}</td>
            <td>{age50?.Post_Test.SW_TG}</td>
            <td>{age50?.Post_Test.CSW_M}</td>
            <td>{age50?.Post_Test.CSW_F}</td>
            <td>{age50?.Post_Test.CSW_TG}</td>
            <td colSpan={2}>{age50?.Post_Test.MSM}</td>
            <td colSpan={2}>{age50?.Post_Test.TGW}</td>
            <td>{age50?.Post_Test.PWID_M}</td>
            <td>{age50?.Post_Test.PWID_F}</td>
            <td>{age50?.Post_Test.PWID_TG}</td>
            <td>{age50?.Post_Test.PWUD_M}</td>
            <td>{age50?.Post_Test.PWUD_F}</td>
            <td>{age50?.Post_Test.PWUD_TG}</td>
            <td>{age50?.Post_Test.PKP_M}</td>
            <td>{age50?.Post_Test.PKP_F}</td>
            <td>{age50?.Post_Test.PKP_TG}</td>
            <td>{age50?.Post_Test.PLHIV_M}</td>
            <td>{age50?.Post_Test.PLHIV_F}</td>
            <td>{age50?.Post_Test.PLHIV_TG}</td>
            <td>{age50?.Post_Test.other_M}</td>
            <td>{age50?.Post_Test.other_F}</td>
            <td>{age50?.Post_Test.other_TG}</td>
          </tr>
          <tr className="text-center">
            <td colSpan={5} className="text-left">
              HIV Positive
            </td>
            <td>{age50?.HIV_Positive.SW_M}</td>
            <td>{age50?.HIV_Positive.SW_F}</td>
            <td>{age50?.HIV_Positive.SW_TG}</td>
            <td>{age50?.HIV_Positive.CSW_M}</td>
            <td>{age50?.HIV_Positive.CSW_F}</td>
            <td>{age50?.HIV_Positive.CSW_TG}</td>
            <td colSpan={2}>{age50?.HIV_Positive.MSM}</td>
            <td colSpan={2}>{age50?.HIV_Positive.TGW}</td>
            <td>{age50?.HIV_Positive.PWID_M}</td>
            <td>{age50?.HIV_Positive.PWID_F}</td>
            <td>{age50?.HIV_Positive.PWID_TG}</td>
            <td>{age50?.HIV_Positive.PWUD_M}</td>
            <td>{age50?.HIV_Positive.PWUD_F}</td>
            <td>{age50?.HIV_Positive.PWUD_TG}</td>
            <td>{age50?.HIV_Positive.PKP_M}</td>
            <td>{age50?.HIV_Positive.PKP_F}</td>
            <td>{age50?.HIV_Positive.PKP_TG}</td>
            <td>{age50?.HIV_Positive.PLHIV_M}</td>
            <td>{age50?.HIV_Positive.PLHIV_F}</td>
            <td>{age50?.HIV_Positive.PLHIV_TG}</td>
            <td>{age50?.HIV_Positive.other_M}</td>
            <td>{age50?.HIV_Positive.other_F}</td>
            <td>{age50?.HIV_Positive.other_TG}</td>
          </tr>
          {/* sixth row */}

          {/* 7th row */}
          <tr>
            <td colSpan={7} className="font-weight-bolder">
              Total people Tested for HIV
            </td>
            <td className="text-center">{bottom?.Total_HIV_Tested.SW_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.SW_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.SW_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.CSW_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.CSW_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.CSW_TG}</td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_HIV_Tested.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_HIV_Tested.TGW}
            </td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWID_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWID_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWID_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWUD_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWUD_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PWUD_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PKP_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PKP_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PKP_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PLHIV_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PLHIV_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.PLHIV_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.other_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.other_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Tested.other_TG}</td>
          </tr>

          {/* 8th row */}

          {/* 7th row */}
          <tr>
            <td colSpan={7}>Number of people tested for HIV through CBS</td>
            <td className="text-center">{bottom?.Total_CBS_HIV_Tested.SW_M}</td>
            <td className="text-center">{bottom?.Total_CBS_HIV_Tested.SW_F}</td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.SW_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.CSW_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.CSW_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.CSW_TG}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_HIV_Tested.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_HIV_Tested.TGW}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWID_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWID_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWID_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWUD_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWUD_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PWUD_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PKP_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PKP_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PKP_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PLHIV_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PLHIV_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.PLHIV_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.other_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.other_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Tested.other_TG}
            </td>
          </tr>

          {/* 8th row */}

          {/* 9th row */}
          <tr>
            <td colSpan={7} className="font-weight-bolder">
              Total people received HIV post test Counselling
            </td>
            <td className="text-center">{bottom?.Total_Post_Test.SW_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.SW_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.SW_TG}</td>
            <td className="text-center">{bottom?.Total_Post_Test.CSW_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.CSW_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.CSW_TG}</td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_Post_Test.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_Post_Test.TGW}
            </td>
            <td className="text-center">{bottom?.Total_Post_Test.PWID_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PWID_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PWID_TG}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PWUD_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PWUD_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PWUD_TG}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PKP_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PKP_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PKP_TG}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PLHIV_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PLHIV_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.PLHIV_TG}</td>
            <td className="text-center">{bottom?.Total_Post_Test.other_M}</td>
            <td className="text-center">{bottom?.Total_Post_Test.other_F}</td>
            <td className="text-center">{bottom?.Total_Post_Test.other_TG}</td>
          </tr>

          {/* 9th row */}

          {/* 10th row */}
          <tr>
            <td colSpan={7}>
              Number of people received HIV post test Counselling through CBS
            </td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.SW_M}</td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.SW_F}</td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.SW_TG}</td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.CSW_M}</td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.CSW_F}</td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.CSW_TG}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_Post_Test.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_Post_Test.TGW}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWID_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWID_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWID_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWUD_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWUD_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PWUD_TG}
            </td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.PKP_M}</td>
            <td className="text-center">{bottom?.Total_CBS_Post_Test.PKP_F}</td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PKP_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PLHIV_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PLHIV_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.PLHIV_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.other_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.other_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_Post_Test.other_TG}
            </td>
          </tr>

          {/* 10th row */}

          {/* 11th row */}
          <tr>
            <td colSpan={7} className="font-weight-bolder">
              Total people HIV tested Positive
            </td>
            <td className="text-center">{bottom?.Total_HIV_Positive.SW_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.SW_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.SW_TG}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.CSW_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.CSW_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.CSW_TG}</td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_HIV_Positive.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_HIV_Positive.TGW}
            </td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PWID_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PWID_F}</td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.PWID_TG}
            </td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PWUD_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PWUD_F}</td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.PWUD_TG}
            </td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PKP_M}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PKP_F}</td>
            <td className="text-center">{bottom?.Total_HIV_Positive.PKP_TG}</td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.PLHIV_M}
            </td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.PLHIV_F}
            </td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.PLHIV_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.other_M}
            </td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.other_F}
            </td>
            <td className="text-center">
              {bottom?.Total_HIV_Positive.other_TG}
            </td>
          </tr>

          {/*11th row */}

          {/* 12th row */}
          <tr>
            <td colSpan={7}>
              Number of people HIV tested Positive(confirmed) through CBS
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.SW_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.SW_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.SW_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.CSW_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.CSW_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.CSW_TG}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_HIV_Positive.MSM}
            </td>
            <td className="text-center" colSpan={2}>
              {bottom?.Total_CBS_HIV_Positive.TGW}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWID_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWID_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWID_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWUD_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWUD_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PWUD_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PKP_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PKP_F}
            </td>

            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PKP_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PLHIV_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PLHIV_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.PLHIV_TG}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.other_M}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.other_F}
            </td>
            <td className="text-center">
              {bottom?.Total_CBS_HIV_Positive.other_TG}
            </td>
          </tr>

          {/* 12th row */}
        </tbody>
      </table>
    </>
  )
}

export default ReportTable
