import DateRangePicker from 'components/Inputs/DateRangePicker'
import TextBox from 'components/Inputs/TextBox'
import React from 'react'
import { Row, Col, Button } from 'reactstrap'
function FilterBox({ onDateChange, onClick }) {
  return (
    <Row className="d-flex align-items-center pt-3 pl-3">
      <Col md={3} xs={5}>
        {/* <TextBox placeholder="Orgization" /> */}
      </Col>
      <Col md={3} xs={5}>
        {/* <TextBox placeholder="Pharmancy Name" /> */}
      </Col>
      <Col md={5} xs={5}>
        <DateRangePicker onDateChange={onDateChange} />
      </Col>
      <Button
        size="sm"
        color="success"
        style={{ height: 35 }}
        onClick={onClick}
      >
        <i className="fa fa-search" />
      </Button>
    </Row>
  )
}

export default FilterBox
