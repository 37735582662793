// nodejs library that concatenates classes
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux'
// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  NavItem,
  NavLink,
} from 'reactstrap'
import { adminLogOutAction } from 'store/actions'
function AdminNavbar({ content }) {
  const admin = useSelector((state) => state.admin.adminInfo)
  // function that on mobile devices makes the search open

  // function that on mobile devices makes the search close
  // const closeSearch = () => {
  //   document.body.classList.remove('g-navbar-search-shown')
  //   setTimeout(function () {
  //     document.body.classList.remove('g-navbar-search-show')
  //     document.body.classList.add('g-navbar-search-hiding')
  //   }, 150)
  //   setTimeout(function () {
  //     document.body.classList.remove('g-navbar-search-hiding')
  //     document.body.classList.add('g-navbar-search-hidden')
  //   }, 300)
  //   setTimeout(function () {
  //     document.body.classList.remove('g-navbar-search-hidden')
  //   }, 500)
  // }

  // const showSearch = () => {
  //   const searchPages = ['/user', '/category', '/product', '/notification']
  //   if (searchPages.includes(router.pathname)) {
  //     return true
  //   }
  //   return false
  // }

  return (
    <>
      <Navbar className="navbar-top navbar-dark bg-primary navbar-expand border-bottom">
        <Container fluid>
          <Collapse
            navbar
            isOpen={true}
            className="d-flex justify-content-between "
          >
            {content}
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
}

export default connect(null, { adminLogOutAction })(AdminNavbar)
