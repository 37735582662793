import React from 'react'
import { getData } from 'services/patientApiService'

const initialState = { data: null, loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PATIENT':
      return { ...state, data: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }

    default:
      throw new Error()
  }
}

const usePatient = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPatient = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })

    let response = await getData(`patients/${id}`)
    dispatch({ type: 'SET_PATIENT', payload: { data: response.data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return { fetchPatient, state: state }
}

export default usePatient
