export const primaryColor = 'red'
export const secondaryColor = 'blue'

export const barChartConfig = {
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end',
      align: 'top',
      offset: 5,
      font: {
        size: 14,
      },
    },
  },
}

export const comboChartConfig = {
  responsive: true,
  elements: {
    line: {
      tension: 0, // Set line tension to 0 for straight lines
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
    x: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        maxRotation: 45, // Maximum rotation for tick labels
        minRotation: 45, // Minimum rotation for tick labels
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    datalabels: {
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0 // Display label only if value is not 0
      },
      color: 'black',
      anchor: 'end',
      align: 'top',
      offset: 5,
      font: {
        size: 14,
      },
    },
  },
}

export const stackedBarCharConfig = {
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          maxRotation: 70, // Maximum rotation for tick labels
          minRotation: 70, // Minimum rotation for tick labels
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
  interaction: {
    intersect: false,
  },
  plugins: {
    datalabels: {
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0 // Display label only if value is not 0
      },
      color: 'white',
      anchor: 'center',
      align: 'center',
      offset: 10,
      font: {
        size: 14,
      },
    },
  },
}

export const lineChartConfig = {
  responsive: true,
  elements: {
    line: {
      tension: 0, // Set line tension to 0 for straight lines
    },
  },
  scales: {
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [
      {
        offset: false,
        stacked: true,
        ticks: {
          beginAtZero: true,

          maxRotation: 70, // Maximum rotation for tick labels
          minRotation: 70, // Minimum rotation for tick labels
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end',
      align: 'top',
      offset: 5,
      font: {
        size: 14,
      },
    },
  },
}

export const transformedStackedBarData = (data, colors) => {
  const labels = data?.map((item) => item.date)
  const sources = [...new Set(data?.flatMap((item) => Object.keys(item.total)))]

  const datasets = sources?.map((source, index) => ({
    label: source?.toUpperCase(),
    data: data?.map((item) => item.total[source] || 0),
    backgroundColor: colors[index], // Helper function to generate random colors
  }))

  return { labels, datasets }
}
export const transformedBarChartData = (data, color, source) => {
  const labels = data?.map((item) => item.date)

  const datasets = [
    {
      label: source?.toUpperCase(),
      data: data?.map((item) => item.total[source] || 0),
      backgroundColor: color,
    },
  ]

  return { labels, datasets }
}

export const transformedLineData = (data) => {
  const labels = data?.map((item) => item.date)

  const datasets = [
    {
      label: 'All PATIENTS',
      data: data?.map((item) => item.total),
      fill: false,
      borderColor: 'blue',
      borderWidth: 1,
    },
    {
      label: 'REACTIVE',
      data: data?.map((item) => item.reactive_count),
      fill: false,
      borderColor: RED,
      borderWidth: 1,
    },
  ]

  return { labels, datasets }
}
export const transformComboChartData = (data) => {
  const labels = []
  const reactiveCounts = []
  const nonReactiveCounts = []
  const totals = []

  data?.forEach((item) => {
    // Null safety checks
    const fromDescription = item.fromDescription || 'Unknown'
    const reactiveCount = item.reactive_count || 0
    const nonReactiveCount = item.non_reactive_count || 0
    const totalCount = item.total || 0

    labels.push(fromDescription)
    reactiveCounts.push(reactiveCount)
    nonReactiveCounts.push(nonReactiveCount)
    totals.push(totalCount)
  })

  return {
    labels,
    datasets: [
      {
        label: 'NON_REACTIVE',
        data: nonReactiveCounts,
        backgroundColor: lightColors2[0],
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
      },
      {
        label: 'REACTIVE',
        data: reactiveCounts,
        backgroundColor: lightColors2[1],
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Total',
        type: 'line',
        data: totals,
        borderColor: 'rgba(255, 159, 64, 1)',
        fill: false,
      },
    ],
  }
}

export const lightColors5 = [
  '#6CA0D8', // Darker Light Blue
  '#6BBF6D', // Darker Light Green
  '#D87D8D', // Darker Light Pink
  '#B7B74D', // Darker Light Yellow
  '#B3B3D9', // Darker Light Lavender
]

export const lightColors2 = [
  '#6CA0D8', // Light Blue
  '#C62828', // Red
]
export const RED = '#C62828'
