export default function SubmittedResultTable({data}) {
  return (
    <table
      className="table table-bordered table-sm d-none"
      id="table_submitted_results"
    >
      <thead>
        <tr className="text-center">
          <th className="align-middle" width={50}>
            Date
          </th>
          <th className="align-middle" width={50}>
            Reactive
          </th>
          <th className="align-middle" width={50}>
            Total Count
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row) => (
          <tr>
            <td className="align-middle">{row?.date}</td>
            <td className="align-middle">{row?.reactive_count}</td>
            <td className="align-middle">{row?.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
