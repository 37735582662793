import React from 'react'

const blue = '#ADD8E6'
const yellow = '#FFFFE0'
function ReportTable({ data }) {
  return (
    <>
      <table className="table table-bordered table-sm" id="table-to-xls">
        <thead>
          <tr className="text-center">
            <th rowSpan={3} className="align-middle" width={50}>
              Distribution Model
            </th>
            <th rowSpan={3} className="align-middle" width={200}>
              Indicators
            </th>
            <th colSpan={6} className="align-middle bg-light">
              Age Group <br /> (year)
            </th>
            <th
              rowSpan={3}
              className="align-middle"
              style={{ backgroundColor: blue }}
            >
              Total
            </th>
            <th
              rowSpan={3}
              className="align-middle"
              style={{ backgroundColor: yellow }}
            >
              SW
            </th>
            <th
              rowSpan={3}
              className="align-middle"
              style={{ backgroundColor: yellow }}
            >
              MSM
            </th>
            <th colSpan={2} style={{ backgroundColor: yellow }}>
              PWID
            </th>
            <th colSpan={2} style={{ backgroundColor: yellow }}>
              PWUD
            </th>
            <th colSpan={2} style={{ backgroundColor: yellow }}>
              Others
            </th>
          </tr>
          <tr className="text-center">
            <th className="bg-light">0-9</th>
            <th className="bg-light">10-14</th>
            <th className="bg-light">15-19</th>
            <th className="bg-light">20-24</th>
            <th className="bg-light">24-29</th>
            <th className="bg-light">{`>50`}</th>
            <th style={{ backgroundColor: yellow }}>M</th>
            <th style={{ backgroundColor: yellow }}>F</th>
            <th style={{ backgroundColor: yellow }}>M</th>
            <th style={{ backgroundColor: yellow }}>F</th>
            <th style={{ backgroundColor: yellow }}>M</th>
            <th style={{ backgroundColor: yellow }}>F</th>
          </tr>
        </thead>
        <tbody>
          {/* first row */}
          <tr className="text-center">
            <td rowSpan={2} className="align-middle">
              Online
            </td>
            <td className="text-left">
              No. of distributed HIV self-test kits received returned results
            </td>
            <td>{data?.online_delivery?.total['9']}</td>
            <td>{data?.online_delivery?.total['14']}</td>
            <td>{data?.online_delivery?.total['19']}</td>
            <td>{data?.online_delivery?.total['24']}</td>
            <td>{data?.online_delivery?.total['29']}</td>
            <td>{data?.online_delivery?.total['50']}</td>
            <td>{data?.online_delivery?.total?.total}</td>
            <td>{data?.online_delivery?.total?.online_delivery_sw}</td>
            <td>{data?.online_delivery?.total?.online_delivery_msm}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwid_male}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwid_female}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwud_male}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwud_female}</td>
            <td>{data?.online_delivery?.total?.online_delivery_other_male}</td>
            <td>
              {data?.online_delivery?.total?.online_delivery_other_female}
            </td>
          </tr>
          <tr className="text-center">
            <td className="text-left">
              No. of individuals receiving unassisted testing
            </td>
            <td>{data?.online_delivery?.unassisted['9']}</td>
            <td>{data?.online_delivery?.unassisted['14']}</td>
            <td>{data?.online_delivery?.unassisted['19']}</td>
            <td>{data?.online_delivery?.unassisted['24']}</td>
            <td>{data?.online_delivery?.unassisted['29']}</td>
            <td>{data?.online_delivery?.unassisted['50']}</td>
            <td>{data?.online_delivery?.unassisted?.total}</td>
            <td>
              {data?.online_delivery?.unassisted?.online_delivery_unassisted_sw}
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_msm
              }
            </td>

            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwid_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwid_female
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwud_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwud_female
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_other_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_other_female
              }
            </td>
          </tr>
          {/* first row */}
          {/* second row */}
          <tr className="text-center">
            <td rowSpan={2} className="align-middle">
              Secondary Distribution
            </td>
            <td className="text-left">
              No. of distributed HIV self-test kits received returned results
            </td>
            <td>{data?.secondary?.total['9']}</td>
            <td>{data?.secondary?.total['14']}</td>
            <td>{data?.secondary?.total['19']}</td>
            <td>{data?.secondary?.total['24']}</td>
            <td>{data?.secondary?.total['29']}</td>
            <td>{data?.secondary?.total['50']}</td>
            <td>{data?.secondary?.total?.total}</td>
            <td>{data?.secondary?.total?.secondary_sw}</td>
            <td>{data?.secondary?.total?.secondary_msm}</td>

            <td>{data?.secondary?.total?.secondary_pwid_male}</td>
            <td>{data?.secondary?.total?.secondary_pwid_female}</td>
            <td>{data?.secondary?.total?.secondary_pwud_male}</td>
            <td>{data?.secondary?.total?.secondary_pwud_female}</td>
            <td>{data?.secondary?.total?.secondary_other_male}</td>
            <td>{data?.secondary?.total?.secondary_other_female}</td>
          </tr>
          <tr className="text-center">
            <td className="text-left">
              No. of individuals receiving unassisted testing
            </td>
            <td>{data?.secondary?.unassisted['9']}</td>
            <td>{data?.secondary?.unassisted['14']}</td>
            <td>{data?.secondary?.unassisted['19']}</td>
            <td>{data?.secondary?.unassisted['24']}</td>
            <td>{data?.secondary?.unassisted['29']}</td>
            <td>{data?.secondary?.unassisted['50']}</td>
            <td>{data?.secondary?.unassisted?.total}</td>
            <td>{data?.secondary?.unassisted?.secondary_unassisted_sw}</td>
            <td>{data?.secondary?.unassisted?.secondary_unassisted_msm}</td>

            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_pwid_male}
            </td>
            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_pwid_female}
            </td>
            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_pwud_male}
            </td>
            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_pwud_female}
            </td>
            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_other_male}
            </td>
            <td>
              {data?.secondary?.unassisted?.secondary_unassisted_other_female}
            </td>
          </tr>
          {/* second row */}
          {/* third row */}
          <tr className="text-center">
            <td rowSpan={2} className="align-middle">
              Facility-based
            </td>
            <td className="text-left">
              No. of distributed HIV self-test kits received returned results
            </td>
            <td>{data?.facility?.total['9']}</td>
            <td>{data?.facility?.total['14']}</td>
            <td>{data?.facility?.total['19']}</td>
            <td>{data?.facility?.total['24']}</td>
            <td>{data?.facility?.total['29']}</td>
            <td>{data?.facility?.total['50']}</td>
            <td>{data?.facility?.total?.total}</td>
            <td>{data?.facility?.total?.facility_sw}</td>
            <td>{data?.facility?.total?.facility_msm}</td>

            <td>{data?.facility?.total?.facility_pwid_male}</td>
            <td>{data?.facility?.total?.facility_pwid_female}</td>
            <td>{data?.facility?.total?.facility_pwud_male}</td>
            <td>{data?.facility?.total?.facility_pwud_female}</td>
            <td>{data?.facility?.total?.facility_other_male}</td>
            <td>{data?.facility?.total?.facility_other_female}</td>
          </tr>
          <tr className="text-center">
            <td className="text-left">
              No. of individuals receiving unassisted testing
            </td>
            <td>{data?.facility?.unassisted['9']}</td>
            <td>{data?.facility?.unassisted['14']}</td>
            <td>{data?.facility?.unassisted['19']}</td>
            <td>{data?.facility?.unassisted['24']}</td>
            <td>{data?.facility?.unassisted['29']}</td>
            <td>{data?.facility?.unassisted['50']}</td>
            <td>{data?.facility?.unassisted?.total}</td>
            <td>{data?.facility?.unassisted?.facility_unassisted_sw}</td>
            <td>{data?.facility?.unassisted?.facility_unassisted_msm}</td>

            <td>{data?.facility?.unassisted?.facility_unassisted_pwid_male}</td>
            <td>
              {data?.facility?.unassisted?.facility_unassisted_pwid_female}
            </td>
            <td>{data?.facility?.unassisted?.facility_unassisted_pwud_male}</td>
            <td>
              {data?.facility?.unassisted?.facility_unassisted_pwud_female}
            </td>
            <td>
              {data?.facility?.unassisted?.facility_unassisted_other_male}
            </td>
            <td>
              {data?.facility?.unassisted?.facility_unassisted_other_female}
            </td>
          </tr>
          {/* third row */}
          {/* 4th row */}
          <tr className="text-center">
            <td rowSpan={2} className="align-middle">
              Home delivery
            </td>
            <td className="text-left">
              No. of distributed HIV self-test kits received returned results
            </td>
            <td>{data?.online_delivery?.total['9']}</td>
            <td>{data?.online_delivery?.total['14']}</td>
            <td>{data?.online_delivery?.total['19']}</td>
            <td>{data?.online_delivery?.total['24']}</td>
            <td>{data?.online_delivery?.total['29']}</td>
            <td>{data?.online_delivery?.total['50']}</td>
            <td>{data?.online_delivery?.total?.total}</td>
            <td>{data?.online_delivery?.total?.online_delivery_sw}</td>
            <td>{data?.online_delivery?.total?.online_delivery_msm}</td>

            <td>{data?.online_delivery?.total?.online_delivery_pwid_male}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwid_female}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwud_male}</td>
            <td>{data?.online_delivery?.total?.online_delivery_pwud_female}</td>
            <td>{data?.online_delivery?.total?.online_delivery_other_male}</td>
            <td>
              {data?.online_delivery?.total?.online_delivery_other_female}
            </td>
          </tr>
          <tr className="text-center">
            <td className="text-left">
              No. of individuals receiving unassisted testing
            </td>
            <td>{data?.online_delivery?.unassisted['9']}</td>
            <td>{data?.online_delivery?.unassisted['14']}</td>
            <td>{data?.online_delivery?.unassisted['19']}</td>
            <td>{data?.online_delivery?.unassisted['24']}</td>
            <td>{data?.online_delivery?.unassisted['29']}</td>
            <td>{data?.online_delivery?.unassisted['50']}</td>
            <td>{data?.online_delivery?.unassisted?.total}</td>
            <td>
              {data?.online_delivery?.unassisted?.online_delivery_unassisted_sw}
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_msm
              }
            </td>

            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwid_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwid_female
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwud_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_pwud_female
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_other_male
              }
            </td>
            <td>
              {
                data?.online_delivery?.unassisted
                  ?.online_delivery_unassisted_other_female
              }
            </td>
          </tr>
          {/* 4th row */}
          {/* 5th row */}
          <tr className="text-center">
            <td rowSpan={2} className="align-middle">
              Total
            </td>
            <td className="text-left">
              No. of distributed HIV self-test kits received returned results
            </td>
            <td>{data?.total?.total['9']}</td>
            <td>{data?.total?.total['14']}</td>
            <td>{data?.total?.total['19']}</td>
            <td>{data?.total?.total['24']}</td>
            <td>{data?.total?.total['29']}</td>
            <td>{data?.total?.total['50']}</td>
            <td>{data?.total?.total?.total}</td>
            <td>{data?.total?.total?.total_sw}</td>
            <td>{data?.total?.total?.total_msm}</td>

            <td>{data?.total?.total?.total_pwid_male}</td>
            <td>{data?.total?.total?.total_pwid_female}</td>
            <td>{data?.total?.total?.total_pwud_male}</td>
            <td>{data?.total?.total?.total_pwud_female}</td>
            <td>{data?.total?.total?.total_other_male}</td>
            <td>{data?.total?.total?.total_other_female}</td>
          </tr>
          <tr className="text-center">
            <td className="text-left">
              No. of individuals receiving unassisted testing
            </td>
            <td>{data?.facility?.unassisted['9']+data?.secondary?.unassisted['9']}</td>
            <td>{data?.facility?.unassisted['14']+data?.secondary?.unassisted['14']}</td>
            <td>{data?.facility?.unassisted['19']+data?.secondary?.unassisted['19']}</td>
            <td>{data?.facility?.unassisted['24']+data?.secondary?.unassisted['24']}</td>
            <td>{data?.facility?.unassisted['29']+data?.secondary?.unassisted['29']}</td>
            <td>{data?.facility?.unassisted['50']+data?.secondary?.unassisted['50']}</td>
            <td>{data?.total?.unassisted?.total}</td>
            <td>{data?.total?.unassisted?.unassisted_total_sw}</td>
            <td>{data?.total?.unassisted?.unassisted_total_msm}</td>

            <td>{data?.total?.unassisted?.unassisted_total_pwid_male}</td>
            <td>{data?.total?.unassisted?.unassisted_total_pwid_female}</td>
            <td>{data?.total?.unassisted?.unassisted_total_pwud_male}</td>
            <td>{data?.total?.unassisted?.unassisted_total_pwud_female}</td>
            <td>{data?.total?.unassisted?.unassisted_total_other_male}</td>
            <td>{data?.total?.unassisted?.unassisted_total_other_female}</td>
          </tr>
          {/* 5th row */}
        </tbody>
      </table>
    </>
  )
}

export default ReportTable
