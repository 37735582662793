import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'
import { storeCache } from 'utli/cache'
import { removeCache } from 'utli/cache'

export async function login(values) {
  try {
    let response = await sendData('admins/login', values)
    storeCache('admin', JSON.stringify({ name: response.data.name }))
    storeCache('admin_access_token', response.data.access_token)
    return response
  } catch (e) {
    NotificationManager.error('Login Failed')
    return false
  }
}

export async function getAll() {
  try {
    let response = await getData('admins')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function deleteData(_id) {
  try {
    await sendData(
      `admins/delete/${_id}`,
      null,
      'DELETE'
    )
    return true;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export function logout() {
  removeCache('admin')
  removeCache('admin_access_token')
}
